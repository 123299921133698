import Stripe from 'stripe';
import copy from 'clipboard-copy';
import {
  LOAD_INVOICES_REQUEST,
  LOAD_INVOICES_DONE,
  LOAD_INVOICES_FAIL,
  ADD_INVOICE_REQUEST,
  ADD_INVOICE_SUCCESS,
  ADD_INVOICE_FAILURE,
  InvoiceEntity,
  UPDATE_INVOICE_REQUEST,
  UPDATE_INVOICE_SUCCESS,
  UPDATE_INVOICE_FAILURE,
  PayingModel,
  PAY_INVOICE_REQUEST,
  PAY_INVOICE_SUCCESS,
  PAY_INVOICE_FAILURE,
  COPY_PAYMENT_LINK_REQUEST,
  COPY_PAYMENT_LINK_SUCCESS,
  COPY_PAYMENT_LINK_FAILURE,
  PaymentInfo,
  UPDATE_PAYMENT_START,
  UPDATE_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_FAIL,
  DELETE_PAYMENT_SUCCESS,
  CLEAR_INVOICES,
  LOAD_SUBSCRIPTIONS_REQUEST,
  SubscriptionEntity,
  LOAD_SUBSCRIPTIONS_DONE,
  LOAD_SUBSCRIPTIONS_FAIL,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILURE,
  DELETE_INVOICE_REQUEST,
  DELETE_INVOICE_SUCCESS,
  DELETE_INVOICE_FAILURE,
  ADD_SUBSCRIPTION_SUCCESS,
  ADD_SUBSCRIPTION_FAILURE,
  ADD_SUBSCRIPTION_REQUEST,
  SEND_INVOICE_REQUEST,
  SEND_INVOICE_SUCCESS,
  SEND_INVOICE_FAILURE,
  ACTIVATE_INVOICE_REQUEST,
  ACTIVATE_INVOICE_SUCCESS,
  ACTIVATE_INVOICE_FAILURE,
  SuccessSentInvoiceResponse,
  VOID_INVOICE,
  UPDATE_INVOICES,
  UPDATE_SUBSCRIPTIONS,
  DELETE_SUBSCRIPTIONS,
  DELETE_INVOICES,
  LOAD_CLIENT_INVOICES_REQUEST,
  LOAD_CLIENT_INVOICES_DONE,
  LOAD_CLIENT_INVOICES_FAIL,
  SET_CAN_PREVIEW_INVOICE,
  SET_INVOICE_PREVIEW_STATUS,
  UPDATE_ACCOUNT_INFO,
  Subscription,
} from 'src/store/payments/types';
import { AppThunkAction } from 'src/store/reduxTypes';
import PaymentsClient from 'src/clients/PaymentsClient';
import ApiUtils from 'src/utils/ApiUtils';
import UsersClient, { StripeTokenResponse } from 'src/clients/UsersClient';
import history from 'src/history';
import { CallApiWithNotification } from 'src/clients/ApiService';
import { updateCompanyCustomer } from 'src/store/clients/actions';
import { Dispatch } from 'redux';
import { analyticsApi } from 'src/services/api/analyticsApi';
import { ApiTags } from 'src/services/api';

type LoadInvoicesInput = {
  startKey?: string; // use startKey to paginate invoices
};

/**
 * Load invoices and dispatch action to update state
 * when nextKey is returned in response call this function
 * recursively until no nextKey is returned
 * @param loadInvoicesInput options for loading invoices
 */
export const loadInvoices =
  (loadInvoicesInput: LoadInvoicesInput = {}): AppThunkAction =>
  async (dispatch) => {
    function request() {
      return { type: LOAD_INVOICES_REQUEST };
    }

    function success(payload: InvoiceEntity[]) {
      return { type: LOAD_INVOICES_DONE, payload };
    }

    function failure(error: string) {
      return { type: LOAD_INVOICES_FAIL, error };
    }

    dispatch(request());

    const paginate = true;
    try {
      // pass startKey to paginate invoices, if no startKey is undefined
      // the option will be ignored
      const result = await PaymentsClient.getInvoices({
        paginate,
        startKey: loadInvoicesInput.startKey,
      });

      if (paginate && result.nextKey) {
        // when nextKey is returned load next page of invoices
        dispatch(loadInvoices({ startKey: result.nextKey }));
      }

      dispatch(success(paginate ? result.items : result));
    } catch (ex) {
      const error = ex as { message: string };
      dispatch(failure(error.message));
    }
  };

export const loadClientInvoices =
  (clientUserId: string): AppThunkAction =>
  async (dispatch) => {
    function request() {
      return { type: LOAD_CLIENT_INVOICES_REQUEST };
    }

    function success(payload: InvoiceEntity[]) {
      return { type: LOAD_CLIENT_INVOICES_DONE, payload };
    }

    function failure(error: string) {
      return { type: LOAD_CLIENT_INVOICES_FAIL, error };
    }

    dispatch(request());

    try {
      const result = await PaymentsClient.getInvoices({
        clientId: clientUserId,
      });

      dispatch(success(result));
    } catch (ex) {
      const error = ex as { message: string };
      dispatch(failure(error.message));
    }
  };

export const loadSubscriptions = (): AppThunkAction => async (dispatch) => {
  function request() {
    return { type: LOAD_SUBSCRIPTIONS_REQUEST };
  }

  function success(payload: SubscriptionEntity[]) {
    return { type: LOAD_SUBSCRIPTIONS_DONE, payload };
  }

  function failure(error: string) {
    return { type: LOAD_SUBSCRIPTIONS_FAIL, error };
  }

  dispatch(request());

  try {
    const result = await PaymentsClient.getSubscriptions();

    dispatch(success(result));
  } catch (ex) {
    const error = ex as { message: string };
    dispatch(failure(error.message));
  }
};

export const cancelSubscription =
  (subscriptionData: SubscriptionEntity) => async (dispatch: Dispatch) => {
    function request() {
      return { type: CANCEL_SUBSCRIPTION_REQUEST };
    }

    function success(payload: SubscriptionEntity) {
      return { type: CANCEL_SUBSCRIPTION_SUCCESS, payload };
    }

    function failure(error: string) {
      return { type: CANCEL_SUBSCRIPTION_FAILURE, error };
    }

    dispatch(request());

    const result = await CallApiWithNotification({
      executeFunction: PaymentsClient.cancelSubscription,
      params: subscriptionData,
      successMessage: `Subscription has been canceled.`,
      errorMessage: `This subscription could not be canceled.`,
      dispatch,
    });

    if (result.status) {
      dispatch(success(result.data));
    } else {
      dispatch(failure(result.data));
    }

    return result;
  };

export const payInvoice =
  (payingData: PayingModel): AppThunkAction =>
  async (dispatch) => {
    function request() {
      return { type: PAY_INVOICE_REQUEST };
    }

    function success(payload: InvoiceEntity) {
      return { type: PAY_INVOICE_SUCCESS, payload };
    }

    function failure(error: string) {
      return { type: PAY_INVOICE_FAILURE, error };
    }

    dispatch(request());

    const result = await CallApiWithNotification({
      executeFunction: PaymentsClient.payInvoice,
      params: payingData,
      successMessage: `Invoice has been paid.`,
      errorMessage: `This invoice could not be paid.`,
      dispatch,
    });

    if (result.status) {
      dispatch(success(result.data));
    } else {
      dispatch(failure(result.data));
    }
  };

export const copyPaymentLink =
  (payingData: PayingModel): AppThunkAction =>
  async (dispatch) => {
    function request() {
      return { type: COPY_PAYMENT_LINK_REQUEST };
    }

    function success() {
      return { type: COPY_PAYMENT_LINK_SUCCESS };
    }

    function failure(error: string) {
      return { type: COPY_PAYMENT_LINK_FAILURE, error };
    }

    dispatch(request());

    const result = await CallApiWithNotification({
      executeFunction: PaymentsClient.copyPaymentLink,
      params: payingData,
      successMessage: `Payment link copied to clipboard`,
      errorMessage: `This invoice link could not be copied.`,
      dispatch,
    });

    if (result.status) {
      copy(result.data.link);
      dispatch(success());
    } else {
      dispatch(failure(result.data));
    }
  };

export const UpdatePaymentStartAction = () => ({
  type: UPDATE_PAYMENT_START,
});

/**
 * Set the payment info for the portal in redux store
 * when there is an accountId load the account to initialize billing properties
 * @param paymentInfo payment info from db
 * @returns
 */
export const SetPaymentInfo =
  (paymentInfo: PaymentInfo | undefined): AppThunkAction =>
  async (dispatch) => {
    dispatch({ type: UPDATE_PAYMENT_SUCCESS, data: paymentInfo });
    if (paymentInfo && paymentInfo.accountId) {
      try {
        const result = await UsersClient.getAccount(paymentInfo.accountId);
        dispatch({ type: UPDATE_ACCOUNT_INFO, payload: result.data });
      } catch (ex) {
        console.error('Failed to initialize account', ex);
      }
    }
  };

export const UpdatePaymentFailAction = (message: string) => ({
  type: UPDATE_PAYMENT_FAIL,
  error: message,
});

/**
 * add payment method with token calling /api/stripe/cardToken
 * if the user is client user, updates customerId of the company
 * if the user is internal, updates payment information
 * @param token the token of payment method
 * @param customerId stripe customer id
 * @param id the id of user payment information
 * @param address udpated address
 * @param isPrimary is for setting payment method as primary
 */

export const AddPaymentMethod =
  (
    userId: string,
    email: string,
    token: string,
    customerId: string,
    isPrimary: boolean,
    portalId: string,
  ): AppThunkAction<Promise<Stripe.CustomerSource | StripeTokenResponse>> =>
  async (dispatch) => {
    const { data } = await CallApiWithNotification({
      executeFunction: UsersClient.createCardToken,
      params: {
        userId,
        email,
        token,
        isPrimary,
        portalId,
        ...(customerId ? { customerId } : {}),
      },
      successMessage: `Payment method has been added.`,
      errorMessage: `Unable to add payment method.`,
      dispatch,
    });

    const { data: resultData } = data;

    const savedCustomerID =
      customerId || ('customerId' in resultData && resultData.customerId);
    if (savedCustomerID) {
      dispatch(updateCompanyCustomer(userId, savedCustomerID));
    } else {
      dispatch(UpdatePaymentFailAction('Unable to save card'));
    }
    return resultData;
  };

/**
 * update source of given customer with sourceData and set source primary according to isPrimary
 * update payment information with updated address
 * @param customerId stripe customer id
 * @param sourceId the source id of this customer
 * @param sourceData updated values of the source
 * @param paymentId the id of user payment information
 * @param address updated address
 * @param isPrimary is for setting this source as primary
 */
export const updateSource =
  (
    customerId: string,
    sourceId: string,
    sourceData: Stripe.CustomerSourceUpdateParams,
    isPrimary: boolean,
  ): AppThunkAction =>
  async (dispatch) => {
    await CallApiWithNotification({
      executeFunction: PaymentsClient.updateSource,
      params: {
        customerId,
        sourceId,
        sourceData,
        isPrimary,
      },
      successMessage: `Payment method has been updated.`,
      errorMessage: `Unable to update payment method.`,
      dispatch,
    });
  };

/**
 * delete source of a given customer
 * @param customerId stripe customer id
 * @param sourceId the source id of this customer
 */
export const deleteSource =
  (
    customerId: string,
    sourceId: string,
    isLastSource: boolean,
  ): AppThunkAction =>
  async (dispatch) => {
    dispatch(UpdatePaymentStartAction());

    const { data } = await CallApiWithNotification({
      executeFunction: PaymentsClient.deleteSource,
      params: {
        customerId,
        sourceId,
        isLastSource,
      },
      successMessage: `Payment method has been deleted.`,
      errorMessage: `Unable to delete the payment method.`,
      dispatch,
    });

    const { data: resultData } = data;
    if (resultData && 'deleted' in resultData) {
      dispatch({ type: DELETE_PAYMENT_SUCCESS });
    } else {
      dispatch(UpdatePaymentFailAction(''));
    }
  };

export const updateUserPaymentInfo =
  (userId: string, paymentInfo: PaymentInfo): AppThunkAction =>
  async (dispatch) => {
    try {
      const response = await UsersClient.UpdatePaymentInfo(userId, paymentInfo);
      dispatch(SetPaymentInfo(response));
    } catch (e) {
      dispatch(UpdatePaymentFailAction('Unable to save payment info'));
    }
  };

export const clearInvoices = (): AppThunkAction => async (dispatch) => {
  dispatch({
    type: CLEAR_INVOICES,
  });
};

export const deleteInvoiceSuccess = (invoiceId: string) => ({
  type: DELETE_INVOICE_SUCCESS,
  payload: invoiceId,
});

export const deleteInvoicesAction = (invoices: InvoiceEntity[]) => ({
  type: DELETE_INVOICES,
  payload: invoices,
});

export const updateInvoicesAction = (invoices: InvoiceEntity[]) => ({
  type: UPDATE_INVOICES,
  payload: invoices,
});

export const deleteSubscriptionsAction = (
  subscriptions: SubscriptionEntity[],
) => ({
  type: DELETE_SUBSCRIPTIONS,
  payload: subscriptions,
});

export const updateSubscriptionsAction = (
  subscriptions: SubscriptionEntity[],
) => ({
  type: UPDATE_SUBSCRIPTIONS,
  payload: subscriptions,
});

export const voidInvoiceAction = (invoiceId: string) => ({
  type: VOID_INVOICE,
  payload: invoiceId,
});

export const setCanPreviewInvoiceAction = (canPreview: boolean) => ({
  type: SET_CAN_PREVIEW_INVOICE,
  payload: canPreview,
});

export const setInvoicePreviewStatusAction = (enabled: boolean) => ({
  type: SET_INVOICE_PREVIEW_STATUS,
  payload: enabled,
});

interface DeleteInvoiceOptions {
  isVoid?: boolean;
}

export const addInvoice =
  (invoiceData: InvoiceEntity): AppThunkAction =>
  async (dispatch) => {
    function request() {
      return { type: ADD_INVOICE_REQUEST };
    }

    function success(payload: InvoiceEntity) {
      return { type: ADD_INVOICE_SUCCESS, payload };
    }

    function failure(error: string) {
      return { type: ADD_INVOICE_FAILURE, error };
    }

    dispatch(request());

    const result = await CallApiWithNotification({
      executeFunction: PaymentsClient.addInvoice,
      checkFunction: ApiUtils.IsBatchCreateResultSuccessful,
      params: invoiceData,
      successMessage: `Invoice has been created.`,
      errorMessage: `This invoice could not be created.`,
      dispatch,
    });

    if (result.status) {
      dispatch(success(result.data.createdItems[0]));
    } else {
      dispatch(failure(result.data));
    }

    return result;
  };

export const updateInvoice =
  (invoiceData: InvoiceEntity): AppThunkAction =>
  async (dispatch) => {
    function request() {
      return { type: UPDATE_INVOICE_REQUEST };
    }

    function success(payload: InvoiceEntity, invoiceId: string) {
      return { type: UPDATE_INVOICE_SUCCESS, payload, invoiceId };
    }

    function failure(error: string) {
      return { type: UPDATE_INVOICE_FAILURE, error };
    }

    dispatch(request());

    const result = await CallApiWithNotification({
      executeFunction: PaymentsClient.updateInvoice,
      checkFunction: ApiUtils.IsBatchCreateResultSuccessful,
      params: invoiceData,
      successMessage: `Invoice has been updated.`,
      errorMessage: `This invoice could not be updated.`,
      dispatch,
    });

    if (result.status) {
      dispatch(success(result.data.createdItems[0], invoiceData.id));
    } else {
      dispatch(failure(result.data));
    }

    return result;
  };

export const deleteInvoice =
  (
    invoiceIdList: Array<string>,
    options: DeleteInvoiceOptions = {},
  ): AppThunkAction =>
  async (dispatch) => {
    function request() {
      return { type: DELETE_INVOICE_REQUEST };
    }

    function failure(error: string) {
      return { type: DELETE_INVOICE_FAILURE, error };
    }

    dispatch(request());

    const result = await CallApiWithNotification({
      executeFunction: PaymentsClient.deleteInvoice,
      params: invoiceIdList,
      successMessage: `Invoice has been ${
        options.isVoid ? 'voided' : 'deleted'
      }.`,
      errorMessage: `This invoice could not be voided. Please try again or contact support`,
      dispatch,
    });

    if (result.status) {
      if (options.isVoid) {
        dispatch(voidInvoiceAction(invoiceIdList.at(0) ?? ''));
      } else {
        dispatch(deleteInvoiceSuccess(invoiceIdList.at(0) ?? ''));
      }
    } else {
      dispatch(failure(result.data));
    }
  };

export const sendInvoice =
  (invoiceId: string): AppThunkAction =>
  async (dispatch) => {
    function request() {
      return { type: SEND_INVOICE_REQUEST };
    }

    function success(payload: SuccessSentInvoiceResponse) {
      return { type: SEND_INVOICE_SUCCESS, payload };
    }

    function failure(error: string) {
      return { type: SEND_INVOICE_FAILURE, error };
    }

    dispatch(request());

    const result = await CallApiWithNotification({
      executeFunction: PaymentsClient.sendInvoice,
      params: invoiceId,
      successMessage: `Invoice has been sent.`,
      errorMessage: `This invoice could not be sent.`,
      dispatch,
    });

    if (result.status) {
      dispatch(success(result.data));
    } else {
      dispatch(failure(result.data));
    }
  };

export const activateInvoice =
  (invoiceId: string): AppThunkAction =>
  async (dispatch) => {
    function redirectToPay(newInvoiceId: string) {
      history.push(`/invoices/pay?invoiceId=${newInvoiceId}`);
    }

    function request() {
      return { type: ACTIVATE_INVOICE_REQUEST };
    }

    function success(payload: Stripe.Invoice, originalInvoiceId: string) {
      return { type: ACTIVATE_INVOICE_SUCCESS, payload, originalInvoiceId };
    }

    function failure(error: string) {
      return { type: ACTIVATE_INVOICE_FAILURE, error };
    }

    dispatch(request());

    const result = await CallApiWithNotification({
      executeFunction: PaymentsClient.activateInvoice,
      params: invoiceId,
      successMessage: '',
      errorMessage: 'This invoice could not be activated.',
      dispatch,
    });

    if (result.status) {
      dispatch(success(result.data, invoiceId));
      setTimeout(() => redirectToPay(result.data.id), 300);
    } else {
      dispatch(failure(result.errorMessage));
    }
  };

export const addSubscription =
  (subscriptionData: SubscriptionEntity): AppThunkAction =>
  async (dispatch) => {
    const subscriptionInfo = subscriptionData;
    // these fields would be set once properly functioning
    delete subscriptionInfo.fields.resetBillingCycle;
    delete subscriptionInfo.fields.prorate;

    function request() {
      return { type: ADD_SUBSCRIPTION_REQUEST };
    }

    function success(payload: SubscriptionEntity) {
      return { type: ADD_SUBSCRIPTION_SUCCESS, payload };
    }

    function failure(error: string) {
      return { type: ADD_SUBSCRIPTION_FAILURE, error };
    }

    dispatch(request());

    const result = await CallApiWithNotification({
      executeFunction: PaymentsClient.addStripeEntity,
      checkFunction: ApiUtils.IsBatchCreateResultSuccessful,
      params: subscriptionInfo,
      successMessage: `Subscription has been created.`,
      errorMessage: `This Subscription could not be created.`,
      dispatch,
    });

    if (result.status) {
      dispatch(success(result.data.createdItems[0]));
      dispatch(
        analyticsApi.util.invalidateTags([ApiTags.analyticsSubscriptions]),
      );
    } else {
      dispatch(failure(result.data));
    }

    return result;
  };

export const editSubscription =
  (data: { subId: string; fields: Subscription }): AppThunkAction =>
  async (dispatch) => {
    const { subId, fields } = data;

    function request() {
      return { type: ADD_SUBSCRIPTION_REQUEST };
    }

    function success(payload: SubscriptionEntity) {
      return { type: ADD_SUBSCRIPTION_SUCCESS, payload };
    }

    function failure(error: string) {
      return { type: ADD_SUBSCRIPTION_FAILURE, error };
    }

    dispatch(request());

    const result = await CallApiWithNotification({
      executeFunction: PaymentsClient.updateSubscription,
      params: { subId, fields },
      successMessage: `Subscription has been updated.`,
      errorMessage: `This Subscription could not be created.`,
      dispatch,
    });

    if (result.status) {
      dispatch(success(result.data));
    } else {
      dispatch(failure(result.data));
    }

    return result;
  };
