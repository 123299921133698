import React from 'react';
import { makeStyles } from '@material-ui/core';
import { BlackHeadings, GraySmall, WebLink } from 'src/theme/colors';
import { typography13MediumStyle } from 'src/components/Text';
import BaseTypography from 'src/components/Text/BaseTypography';
import { parseMessageToHTML } from 'src/utils/UrlUtils';

type Props = {
  description: string;
};

const useStyles = makeStyles(() => ({
  link: {
    '&>a': {
      color: WebLink,
      ...typography13MediumStyle,
      '&:hover': {
        color: BlackHeadings,
      },
    },
  },
  description: {
    color: GraySmall,
  },
}));

export const RichTextDescription = ({ description }: Props) => {
  const classes = useStyles();
  const parsedDescription = parseMessageToHTML(description);
  return (
    <BaseTypography fontType="13Regular" className={classes.description}>
      <div
        className={classes.link}
        dangerouslySetInnerHTML={{ __html: parsedDescription }}
      />
    </BaseTypography>
  );
};
