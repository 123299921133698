import { ApiMethods, ApiTags, appAPI } from '.';
import { API } from 'src/utils/AmplifyApiUtils';
import { AppAPIName } from 'src/constants';
import { notify } from 'src/clients/ApiService';
import {
  InvoiceSchema,
  InvoiceTemplateSchema,
  LineItemsSchema,
} from 'src/legacy/components/billing/new/types';
import { ensureApiError } from 'src/utils/Errors';
import { convertAmountToCents } from 'src/utils/InvoiceUtils';
import { RootState } from 'src/store';
import { arrayToMap } from 'src/utils/array';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

export const MIN_LINE_ITEM_AMOUNT = 0.5;
export const MAX_LINE_ITEM_AMOUNT = 100000;
export const MAX_LINE_ITEM_QUANTITY = 1000000;
export const MAX_TOTAL_AMOUNT = 999999;
export const MIN_LINE_ITEM_AMOUNT_ERROR = `Total must be at least ${MIN_LINE_ITEM_AMOUNT}.`;
export const MAX_TOTAL_AMOUNT_ERROR = `Total must be at most ${new Intl.NumberFormat(
  'en-US',
).format(MAX_TOTAL_AMOUNT)}.`;

export enum Currencies {
  USD = 'USD',
}

export enum PaymentSourceType {
  Account = 'account',
  AlipayAccount = 'alipay_account',
  BankAccount = 'bank_account',
  BitcoinReceiver = 'bitcoin_receiver',
  Card = 'card',
  Source = 'source',
}

export enum StripeCardStatus {
  New = 'new',
  Validated = 'validated',
  Verified = 'verified',
  VerificationFailed = 'verification_failed',
  Errored = 'errored',
}
export enum InvoiceCollectionMethod {
  SendInvoice = 'sendInvoice',
  ChargeAutomatically = 'chargeAutomatically',
}

export enum InvoiceIncompleteReasons {
  NO_BANKING_INFO = 'no-banking-info',
  NO_PAYMENT_METHOD = 'no-payment-method',
  NON_USD_SUBSCRIPTION = 'non-usd-subscription',
}

export enum LineItemType {
  Product = 'product',
  OneTime = 'oneTime',
}

export enum InvoiceStatus {
  Draft = 'draft',
  Open = 'open',
  Void = 'void',
  Uncollectible = 'uncollectible',
  Paid = 'paid',
}

export enum PaymentStatus {
  Retrying = 'retrying',
  PastDue = 'pastDue',
  Cancelled = 'cancelled',
  Success = 'succeeded',
  Failed = 'failed',
}

export enum PaymentMethod {
  BankAccount = 'bankAccount',
  CreditCard = 'creditCard',
}

export enum StripeEntityType {
  Invoice = 'stripe_invoice',
  Subscription = 'stripe_subscription',
}

export enum InvoicePreviewType {
  Checkout = 'checkout',
  Invoice = 'invoice',
  Email = 'email',
}

export type InvoicePaymentOptions = {
  allowPaymentViaACH: boolean;
  allowPaymentViaCC: boolean;
  absorbTransactionFees?: boolean;
  absorbACHFees?: boolean;
  absorbCreditCardFee?: boolean;
  allowPlaidSetup?: boolean;
};

export const PAYMENT_METHOD_MAPPER = {
  bankAccount: 'Bank Account',
  creditCard: 'Credit Card',
};

export interface InvoiceDetailOptions {
  id: string;
  amountDue: number;
  fees: number;
  tax: number;
  currency: string;
  invoiceNumber: string;
  dueDate: string;
  dateOfIssue: string;
  description?: string;
  collectionMethod: string;
  attachmentKeys: string[];
  fileKey?: string;
  identityId?: string;
  ignoreFees: boolean;
  taxPercentage?: string;
}

export type LineItem = {
  id: string;
  productId?: string;
  priceId?: string;
  quantity: string;
  amount?: string;
  description?: string;
  type: LineItemType;
};

export type PaymentPreferences = {
  paymentMethodTypes: PaymentMethod[];
  absorbTransactionFee: PaymentMethod[];
};

type QuickBooksData = {
  invoiceId: string;
  paymentId: string;
};

export enum RecipientType {
  Client = 'client',
  Company = 'company',
}

type FeeItems = {
  amount: number;
  type: 'string';
};

export type Fee = {
  isFeeAbsorbed: boolean;
  absorbedAmount: number;
  chargedAmount: number;
  totalAmount: number;
  items: FeeItems;
};

export interface Payment {
  message: string;
  status: PaymentStatus;
  invoiceId: string;
  portalId: string;
  amount: number;
  paymentMethod: PaymentMethod;
  last4Digits: string;
  stripeChargeId: string;
  chargedAt: string;
  createdAt: string;
  updatedAt: string;
  fee: Fee | undefined;
  ref: string;
}

type ListInvoiceResponse = {
  data: Invoice[];
  nextToken?: string;
};

export type PayInvoiceInput = {
  invoiceId: string;
  sourceId?: string;
  manual: boolean;
  confirmationToken?: string;
};

type InvoiceMetaData = {
  total: number;
  currency: string;
  taxPercentage: number;
  taxAmount: number;
  memo: string;
  attachmentKeys: string[] | null;
  lineItems: LineItemsSchema;
};

export type InvoiceTemplate = {
  id?: string;
  name?: string;
} & InvoiceMetaData;

export type Invoice = {
  id: string;
  number: string;
  subscriptionId?: string | null;
  recipientId: string;
  status: InvoiceStatus;
  dueDate: string;
  paymentStatus: PaymentStatus;
  collectionMethod: InvoiceCollectionMethod;
  paymentPreferences: PaymentPreferences;
  nextPaymentAttemptDate?: Date | null;
  amountPaid: number;
  fileUrl: string;
  quickBooksData: QuickBooksData;
  sentDate?: string | null;
  defaultListIndexPkey: string;
  payment: Payment[] | null;
  createdAt: string;
  ref: string;
  fileKey?: string;
  identityId?: string;
  paymentSuccessDate?: string;
  interval?: string;
  receiptKey?: string;
  receiptNumber?: string;
} & InvoiceMetaData;

type DuplicateInvoiceInput = InvoiceSchema & {
  status: InvoiceStatus.Draft;
};

type UpdateInvoiceInput = InvoiceSchema & {
  id: string;
};

type SendDraftInvoice = UpdateInvoiceInput & {
  status: InvoiceStatus.Open;
};

async function loadInvoices(
  previousItems: Invoice[],
  nextToken?: string,
  clientId?: string,
): Promise<Invoice[]> {
  const url = clientId ? `/invoices?clientId=${clientId}` : '/invoices';
  const response: ListInvoiceResponse = await API.get(AppAPIName, url, {
    queryStringParameters: {
      limit: 1000,
      nextToken,
    },
  });

  const responseItems = response?.data ? response.data : [];
  const updatedItems = [...previousItems, ...responseItems];

  if (!response.nextToken) {
    return updatedItems;
  }

  return loadInvoices(updatedItems, response.nextToken, clientId);
}

/**
 * This method is used to get the success message for the invoice create and update
 * actions. It takes into consideration many scenarios
 * - If the invoice is charge automatically and the status is open, but it is failing to charge
 * - If the invoice is charge automatically and the status is open, and it is successfully charged
 */
const getInvoiceActionSuccessMessage = (invoiceInput: {
  collectionMethod: InvoiceCollectionMethod;
  status: InvoiceStatus;
  id?: string;
}) => {
  const { id, collectionMethod, status } = invoiceInput;
  const actionName = id ? 'updated' : 'created';
  let invoiceSuccessMessage = `Invoice has been ${actionName}.`;
  if (
    collectionMethod === InvoiceCollectionMethod.ChargeAutomatically &&
    status === InvoiceStatus.Open
  ) {
    // for charge automatically invoices, we expect the invoice to be be in paid status
    // but if it's not, then it means that the charge failed for some reason
    // and the invoice is still open, so we communicate to that to the user as well
    invoiceSuccessMessage = `Invoice has been ${actionName} but failed to charge automatically`;
  }
  return invoiceSuccessMessage;
};

const formatLineItems = (lineItems: LineItem[]) => {
  return lineItems.map((item) => {
    const isOneoffLineItem = item.type === LineItemType.OneTime;
    const itemVal = {
      id: item.id,
      quantity: Number(item.quantity),
      amount: convertAmountToCents(Number(item.amount)),
      description: item.description,
      type: item.type,
    };
    if (isOneoffLineItem) {
      return itemVal;
    }
    return {
      ...itemVal,
      productId: item.productId,
      priceId: item.priceId,
    };
  });
};

type UpdateInvoiceTemplateInput = {
  id: string;
} & InvoiceTemplateSchema;

export const invoicesApi = appAPI.injectEndpoints({
  endpoints: (build) => ({
    deleteInvoice: build.mutation<string, { id: string; recipientId: string }>({
      query: (params) => ({
        path: `/invoices/${params.id}`,
        method: ApiMethods.del,
        options: {},
      }),
      async onQueryStarted(param, { dispatch, queryFulfilled, getState }) {
        const state = getState() as unknown as RootState;
        const { companies, clients } = state.clients;
        const companiesMap = arrayToMap(companies, 'id');
        const company = companiesMap.get(param.recipientId);
        let clientInvoicePatch;

        if (company) {
          const companyClientsIds = clients
            .filter((client) => client.fields.companyId === param.recipientId)
            .map((client) => client.id);
          for (const clientId of companyClientsIds) {
            clientInvoicePatch = dispatch(
              invoicesApi.util.updateQueryData(
                'getInvoicesByClientId',
                clientId,
                (draft) => draft.filter((inv: Invoice) => inv.id !== param.id),
              ),
            );
          }
        } else {
          clientInvoicePatch = dispatch(
            invoicesApi.util.updateQueryData(
              'getInvoicesByClientId',
              param.recipientId,
              (draft) => draft.filter((inv: Invoice) => inv.id !== param.id),
            ),
          );
        }

        const listInvoicePatch = dispatch(
          invoicesApi.util.updateQueryData('getInvoices', undefined, (draft) =>
            draft.filter((inv: Invoice) => inv.id !== param.id),
          ),
        );

        notify({
          status: 'success',
          successMessage: 'Invoice is deleted successfully.',
          dispatch,
        });

        try {
          await queryFulfilled;
        } catch (error) {
          listInvoicePatch.undo();
          clientInvoicePatch?.undo();
          notify({
            status: 'error',
            errorMessage: 'Invoice can not be deleted.',
            error,
            dispatch,
          });
        }
      },
    }),
    voidInvoice: build.mutation<Invoice, { id: string; recipientId: string }>({
      query: (params) => ({
        path: `/invoices/${params.id}/void`,
        method: ApiMethods.post,
        options: {},
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled, getState }) {
        const state = getState() as unknown as RootState;
        const result = await queryFulfilled;
        const invoice = result.data;

        const { listInvoicesPatch, clientInvoicePatch } = updateInvoiceQuery(
          invoice,
          dispatch,
          state,
        );

        const getInvoiceByIdPatch = dispatch(
          invoicesApi.util.updateQueryData(
            'getInvoiceById',
            param.id,
            (draft) => {
              if (draft) {
                draft.status = InvoiceStatus.Void;
              }
            },
          ),
        );

        try {
          await queryFulfilled;
          notify({
            status: 'success',
            successMessage: 'Invoice is voided successfully.',
            dispatch,
          });
        } catch (error) {
          listInvoicesPatch.undo();
          clientInvoicePatch?.undo();
          getInvoiceByIdPatch.undo();
          notify({
            status: 'error',
            errorMessage: 'Invoice can not be void.',
            error,
            dispatch,
          });
        }
      },
    }),

    createInvoice: build.mutation<Invoice, InvoiceSchema>({
      query: (invoice: InvoiceSchema) => ({
        path: '/invoices',
        method: ApiMethods.post,
        options: {
          body: {
            ...invoice,
            taxPercentage: Number(invoice.taxPercentage),
            lineItems: formatLineItems(invoice.lineItems),
            dueDate:
              invoice.collectionMethod === InvoiceCollectionMethod.SendInvoice
                ? invoice.dueDate
                : null,
          },
        },
      }),
      async onQueryStarted(payload, { dispatch, queryFulfilled, getState }) {
        try {
          const result = await queryFulfilled;
          const invoice = result.data;
          // This is unforunate type casting by RTK docs mention this way to type `getState`
          const state = getState() as unknown as RootState;
          if (invoice) {
            updateInvoiceQuery(invoice, dispatch, state);
          }

          const invoiceSuccessMessage = getInvoiceActionSuccessMessage({
            collectionMethod: payload.collectionMethod,
            status: result.data.status,
          });
          notify({
            status: 'success',
            successMessage: invoiceSuccessMessage,
            dispatch,
          });
        } catch (err) {
          const error = ensureApiError(err);
          const errorMessage = error?.message;
          notify({
            status: 'error',
            errorMessage: errorMessage || 'Error occurs while paying invoice.',
            dispatch,
          });
        }
      },
    }),
    updateInvoice: build.mutation<Invoice, UpdateInvoiceInput>({
      query: (invoice) => ({
        path: `/invoices/${invoice.id}`,
        method: ApiMethods.put,
        options: {
          body: {
            ...invoice,
            taxPercentage: Number(invoice.taxPercentage),
            lineItems: formatLineItems(invoice.lineItems),
            dueDate:
              invoice.collectionMethod === InvoiceCollectionMethod.SendInvoice
                ? invoice.dueDate
                : null,
          },
        },
      }),
      async onQueryStarted(payload, { dispatch, queryFulfilled, getState }) {
        try {
          const result = await queryFulfilled;
          const invoice = result.data;
          // This is unforunate type casting by RTK docs mention this way to type `getState`
          const state = getState() as unknown as RootState;
          if (invoice) {
            updateInvoiceQuery(invoice, dispatch, state);
          }

          const invoiceSuccessMessage = getInvoiceActionSuccessMessage({
            collectionMethod: payload.collectionMethod,
            status: result.data.status,
            id: payload.id,
          });

          notify({
            status: 'success',
            successMessage: invoiceSuccessMessage,
            dispatch,
          });
        } catch (err) {
          const error = ensureApiError(err);
          const errorMessage = error?.message;
          notify({
            status: 'error',
            errorMessage: errorMessage || 'Error occurs while paying invoice.',
            dispatch,
          });
        }
      },
    }),
    sendDraftInvoice: build.mutation<Invoice, SendDraftInvoice>({
      query: (invoice) => ({
        path: `/invoices/${invoice.id}`,
        method: ApiMethods.put,
        options: {
          body: {
            ...invoice,
            taxPercentage: Number(invoice.taxPercentage),
            lineItems: invoice.lineItems,
            dueDate:
              invoice.collectionMethod === InvoiceCollectionMethod.SendInvoice
                ? invoice.dueDate
                : null,
          },
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
        try {
          const result = await queryFulfilled;
          const invoice = result.data;
          // This is unforunate type casting by RTK docs mention this way to type `getState`
          const state = getState() as unknown as RootState;
          if (invoice) {
            updateInvoiceQuery(invoice, dispatch, state);
          }

          notify({
            status: 'success',
            successMessage: 'Invoice sent successfully.',
            dispatch,
          });
        } catch (err) {
          const error = ensureApiError(err);
          const errorMessage = error?.message;
          notify({
            status: 'error',
            errorMessage: errorMessage || 'Error occurs while sending invoice.',
            dispatch,
          });
        }
      },
    }),
    // Duplicate invoice uses the same endpoint as create invoice but with a 'draft' status
    duplicateInvoice: build.mutation<Invoice, DuplicateInvoiceInput>({
      query: (invoice) => ({
        path: '/invoices',
        method: ApiMethods.post,
        options: {
          body: {
            ...invoice,
            taxPercentage: Number(invoice.taxPercentage),
            // note that line items are not formatted here.
            // we already have the right format from the source invoice being
            // duplicated
            lineItems: invoice.lineItems,
            dueDate:
              invoice.collectionMethod === InvoiceCollectionMethod.SendInvoice
                ? invoice.dueDate
                : null,
          },
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
        try {
          const result = await queryFulfilled;
          const invoice = result.data;
          // This is unforunate type casting by RTK docs mention this way to type `getState`
          const state = getState() as unknown as RootState;
          if (invoice) {
            updateInvoiceQuery(invoice, dispatch, state);
          }

          notify({
            status: 'success',
            successMessage: 'Invoice has been created.',
            dispatch,
          });
        } catch (err) {
          const error = ensureApiError(err);
          const errorMessage = error?.message;
          notify({
            status: 'error',
            errorMessage: errorMessage || 'Error occurs while paying invoice.',
            dispatch,
          });
        }
      },
    }),
    getInvoices: build.query<Invoice[], void>({
      queryFn: async () => {
        // fetch invoices with pagination
        const response = await loadInvoices([]);
        return {
          data: response.sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
          ),
        };
      },
      providesTags: [ApiTags.invoices],
    }),
    getInvoicesByClientId: build.query<Invoice[], string>({
      queryFn: async (clientId) => {
        // fetch invoices with pagination
        const response = await loadInvoices([], undefined, clientId);
        return {
          data: response.sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
          ),
        };
      },
      providesTags: [ApiTags.clientInvoices],
    }),
    getInvoiceById: build.query<Invoice, string>({
      query: (invoiceId) => ({
        path: `/invoices/${invoiceId}`,
        method: ApiMethods.get,
        options: {},
      }),
    }),
    payInvoice: build.mutation<Invoice, PayInvoiceInput>({
      query: (invoice) => ({
        path: `/invoices/${invoice.invoiceId}/pay`,
        method: ApiMethods.post,
        options: {
          body: {
            ...invoice,
          },
        },
      }),

      async onQueryStarted(param, { dispatch, queryFulfilled, getState }) {
        try {
          const result = await queryFulfilled;
          const invoice = result.data;
          const state = getState() as unknown as RootState;
          updateInvoiceQuery(invoice, dispatch, state);

          dispatch(
            invoicesApi.util.updateQueryData(
              'getInvoiceById',
              param.invoiceId,
              (draft) => {
                if (result.data) {
                  Object.assign(draft, result.data);
                }
              },
            ),
          );
          notify({
            status: 'success',
            successMessage: 'Invoice has been paid',
            dispatch,
          });
        } catch (err) {
          const error = ensureApiError(err);
          const errorMessage = error.message;
          notify({
            status: 'error',
            errorMessage: errorMessage || 'This invoice could not be paid.',
            dispatch,
          });
        }
      },
    }),
    createInvoiceTemplate: build.mutation<
      InvoiceTemplate,
      InvoiceTemplateSchema
    >({
      query: (invoiceTemplate) => ({
        path: '/invoice-templates',
        method: ApiMethods.post,
        options: {
          body: {
            ...invoiceTemplate,
            taxPercentage: Number(invoiceTemplate.taxPercentage),
            lineItems: formatLineItems(invoiceTemplate.lineItems),
          },
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;

          dispatch(
            invoicesApi.util.updateQueryData(
              'listInvoiceTemplates',
              undefined,
              (draft) => {
                draft.unshift(result.data);
              },
            ),
          );
          if (result.data) {
            notify({
              status: 'success',
              successMessage: 'Invoice template created successfully.',
              dispatch,
            });
          }
        } catch (err) {
          const error = ensureApiError(err);
          const errorMessage = error.message;
          notify({
            status: 'error',
            errorMessage:
              errorMessage ||
              'Error occurs while creating the invoice template.',
            dispatch,
          });
        }
      },
    }),
    updateTemplate: build.mutation<InvoiceTemplate, UpdateInvoiceTemplateInput>(
      {
        query: (invoiceTemplate) => ({
          path: `/invoice-templates`,
          method: ApiMethods.put,
          options: {
            body: {
              ...invoiceTemplate,
              taxPercentage: Number(invoiceTemplate.taxPercentage),
              lineItems: formatLineItems(invoiceTemplate.lineItems),
            },
          },
        }),
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            const result = await queryFulfilled;

            dispatch(
              invoicesApi.util.updateQueryData(
                'listInvoiceTemplates',
                undefined,
                (draft) => {
                  const index = draft.findIndex(
                    (template) => template.id === result.data.id,
                  );
                  draft.splice(index, 1, result.data);
                },
              ),
            );
            if (result.data) {
              notify({
                status: 'success',
                successMessage: 'Invoice template updated successfully.',
                dispatch,
              });
            }
          } catch (err) {
            const error = ensureApiError(err);
            const errorMessage = error?.message;
            notify({
              status: 'error',
              errorMessage:
                errorMessage ||
                'Error occurs while updating the invoice template.',
              dispatch,
            });
          }
        },
      },
    ),

    listInvoiceTemplates: build.query<InvoiceTemplate[], void>({
      query: () => ({
        path: '/invoice-templates',
        method: ApiMethods.get,
        options: {},
      }),
    }),
    removeInvoiceTemplate: build.mutation<string, string>({
      query: (id) => ({
        path: `/invoice-templates/${id}`,
        method: ApiMethods.del,
        options: {},
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const patch = dispatch(
          invoicesApi.util.updateQueryData(
            'listInvoiceTemplates',
            undefined,
            (draft) => draft.filter((template) => template.id !== id),
          ),
        );

        try {
          await queryFulfilled;
          notify({
            status: 'success',
            successMessage: 'Template deleted successfully.',
            dispatch,
          });
        } catch (error) {
          patch.undo();
          notify({
            status: 'error',
            errorMessage: 'A problem occurred while deleting the template.',
            error,
            dispatch,
          });
        }
      },
    }),
  }),
});

export const updateInvoiceQuery = (
  invoice: Invoice,
  dispatch: ThunkDispatch<any, any, AnyAction>,
  state: RootState,
) => {
  let listInvoicesPatch;
  let clientInvoicePatch;
  const { recipientId } = invoice;
  const { companies, clients } = state.clients;
  const companiesMap = arrayToMap(companies, 'id');
  // If found, this indicates that this is a company invoice
  const company = companiesMap.get(recipientId);

  if (company) {
    // For company invoice, we need to update the client details page for all client for this company
    const companyClientsIds = clients
      .filter((client) => client.fields.companyId === recipientId)
      .map((client) => client.id);
    for (const clientId of companyClientsIds) {
      clientInvoicePatch = dispatch(
        invoicesApi.util.updateQueryData(
          'getInvoicesByClientId',
          clientId,
          (draft) => {
            const index = draft.findIndex((i) => i.id === invoice.id);
            if (index == -1) {
              draft.unshift(invoice);
            } else {
              draft.splice(index, 1, invoice);
            }
          },
        ),
      );
    }
  } else {
    // Update the client details invoice list for that particular client
    if (recipientId) {
      clientInvoicePatch = dispatch(
        invoicesApi.util.updateQueryData(
          'getInvoicesByClientId',
          recipientId,
          (draft) => {
            const index = draft.findIndex((i) => i.id === invoice.id);
            if (index == -1) {
              draft.unshift(invoice);
            } else {
              draft.splice(index, 1, invoice);
            }
          },
        ),
      );
    }
  }
  // Update the global invoice list
  listInvoicesPatch = dispatch(
    invoicesApi.util.updateQueryData('getInvoices', undefined, (draft) => {
      if (invoice) {
        const index = draft.findIndex((i) => i.id === invoice.id);
        if (index == -1) {
          draft.unshift(invoice);
        } else {
          draft.splice(index, 1, invoice);
        }
      }
    }),
  );

  return { listInvoicesPatch, clientInvoicePatch };
};

export const {
  useDeleteInvoiceMutation,
  useVoidInvoiceMutation,
  useCreateInvoiceMutation,
  useGetInvoicesQuery,
  usePayInvoiceMutation,
  useGetInvoiceByIdQuery,
  useCreateInvoiceTemplateMutation,
  useUpdateTemplateMutation,
  useListInvoiceTemplatesQuery,
  useDuplicateInvoiceMutation,
  useRemoveInvoiceTemplateMutation,
  useGetInvoicesByClientIdQuery,
  useUpdateInvoiceMutation,
  useSendDraftInvoiceMutation,
} = invoicesApi;
