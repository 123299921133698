import { Auth } from 'aws-amplify';
import Axios, { AxiosResponse } from 'axios';
import { CognitoUser as AwsCognitoUser } from 'amazon-cognito-identity-js';
import Stripe from 'stripe';
import { API } from 'src/utils/AmplifyApiUtils';
import {
  StripeInvoice,
  UserRolesTypesUnMapped,
  MessageSettings,
  User,
  BillingSettings,
  PortalSettings,
  ItemsCount,
  QuickbooksSettings,
  CrmSettings,
  CrmTableSettings,
  ModuleSettingsItem,
  TableProperties,
  UsersAPIName,
  UnAuthAPIName,
} from 'src/constants';
import { AppSessionData } from 'src/constants/dataTypes/sessionDataTypes';
import { Client, ClientFormData, Company } from 'src/store/clients/types';
import { PaymentInfo } from 'src/store/payments/types';
import { FileChannel } from 'src/store/files/types';
import { Resource } from 'src/store/data/types';
import { Notifications } from 'src/store/notifications/types';
import { Operations } from 'src/store/user/types';
import { ExtensionItem } from 'src/store/dashboard/types';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { SetExtraSessionResponse } from 'src/types/api/session';

export interface CognitoUser extends AwsCognitoUser {
  attributes: Record<string, string>;
  username: string;
}

interface CardData {
  userId: string;
  email: string;
  customerId: string;
  token: string;
  isPrimary: boolean;
  portalId: string;
}

export interface StripeTokenResponse {
  customerId: string;
}

interface StripeSessionResponse {
  sessionId: string;
}

export type UserGetData = {
  portalId: string;
  groups?: string[];
  clients: Client[];
  resources: Resource[];
  companies: Company[];
  users: Client[];
  fileChannels: FileChannel[];
  notifications: Notifications;
  stripeCustomer?: Stripe.Customer;
  stripeSubscriptions?: Stripe.Subscription[];
  internalUsers: User[] | null;
  superUser?: boolean;
  portalSettings?: PortalSettings;
  messageSettings: MessageSettings;
  crmTableProperties?: TableProperties;
  extensionItems: ExtensionItem[];
  permissions: Record<string, Operations>;
  isClientUser: boolean;
  userId: string;
};

export interface LoadUserDataResponse {
  data: UserGetData;
}

interface GetSettingsResponse {
  paymentInfo?: PaymentInfo;
  invoices: StripeInvoice[];
  messageSettings: MessageSettings;
  crmSettings: CrmSettings;
  crmTableSettings: CrmTableSettings;
  billingSettings: BillingSettings;
  moduleSettings: ModuleSettingsItem[];
  itemsCount: ItemsCount;
  quickbooksSettings: QuickbooksSettings;
  prices: Stripe.Price[];
  minimumSeats: number;
}

interface GetAuthUserOpts {
  getCognitoUser?: boolean;
  portalId?: string;
  portalName?: string;
}

interface SetWorkspaceOutput {
  redirect: Location;
}

interface GetSessionOutput {
  portalIdToSession: Record<string, AppSessionData>;
  currentPortalSession: string;
}

interface QuickbookAuthRequestBody {
  portalId?: string;
  redirectUrl?: string;
}

// AddPasswordInput captures the input required to add a password
interface AddPasswordInput {
  // userId is the user for which we are adding password
  userId: string;
  // newPassword is the password we are trying to set
  newPassword?: string;
  // generatePassword is set, if we want backend to generate a new password
  generatePassword?: boolean;
  // authSource indicates the federated provided which is completing the auth
  authSource?: CognitoHostedUIIdentityProvider;
}

export type UserAccessAttributes = {
  isClientAccessLimited: boolean;
  companyAccessList: string[];
};

export default class UsersClient {
  static setUserSession(appSessionData?: AppSessionData) {
    return Axios({
      method: 'post',
      url: '/auth/session',
      data: { appSessionData },
    });
  }

  static setExtraPortalSession(
    portalId: string,
    hostname: string,
    email: string,
  ) {
    return Axios.post<SetExtraSessionResponse>('/auth/extra-session', {
      portalId,
      hostname,
      email,
    });
  }

  static getSessions(): Promise<AxiosResponse<GetSessionOutput>> {
    return Axios({
      method: 'get',
      url: '/auth/session',
    });
  }

  static endSessions(): Promise<AxiosResponse<void>> {
    return Axios({
      method: 'get',
      url: '/auth/signout-all',
    });
  }

  static endCurrentSession(): Promise<AxiosResponse<void>> {
    return Axios({
      method: 'get',
      url: '/auth/signout',
    });
  }

  /**
   * Call web server api to set the app cookie for the user and session data
   * @param user cognito user to set sessions for
   * @param portalId id of portal to associate app session data with
   * @returns
   */
  static async setAppUserCookie(
    user: AwsCognitoUser,
    portalId?: string,
    portalName?: string,
    email?: string,
  ) {
    const session = user.getSignInUserSession();
    if (session) {
      const accessToken = session.getAccessToken().getJwtToken();
      let appSessionData: AppSessionData | undefined;
      if (portalId) {
        appSessionData = {
          userId: user.getUsername(),
          portalId,
          accessToken,
          hostname: portalName,
          email,
        };
      }
      await this.setUserSession(appSessionData);
    }
  }

  static async getAuthUser(opts: GetAuthUserOpts): Promise<CognitoUser> {
    const { getCognitoUser = false, portalId, portalName } = opts || {};
    const authUser: CognitoUser = await Auth.currentAuthenticatedUser();
    if (getCognitoUser) {
      return authUser;
    }

    const attributes = await Auth.userAttributes(authUser);
    const attributesMap: Record<string, string> = {};
    attributes.forEach((att) => {
      attributesMap[att.getName()] = att.getValue();
    });
    await this.setAppUserCookie(
      authUser,
      portalId,
      portalName,
      attributesMap.email,
    );

    authUser.attributes = attributesMap;
    return authUser;
  }

  static setSessions() {
    return Axios({
      method: 'get',
      url: `/auth/session`,
    });
  }

  static setWorkspace(appSessionData: AppSessionData) {
    return Axios.post<SetWorkspaceOutput>('/auth/workspace', {
      appSessionData,
    });
  }

  static getCustomer(
    stripeCustomerId: string,
  ): Promise<AxiosResponse<Stripe.Customer>> {
    const url = '/api/stripe/customer';
    return Axios({
      method: 'get',
      url,
      params: {
        stripeCustomerId,
      },
    });
  }

  static getAccount(
    stripeAccountId: string,
  ): Promise<AxiosResponse<Stripe.Account>> {
    const url = `/api/stripe/connect/${stripeAccountId}/status`;
    return Axios({
      method: 'get',
      url,
      params: {},
    });
  }

  static getAccountLoginLink(
    stripeAccountId: string,
  ): Promise<AxiosResponse<Stripe.LoginLink>> {
    const url = `/api/stripe/connect/${stripeAccountId}/loginlink`;
    return Axios({
      method: 'get',
      url,
      params: {},
    });
  }

  static getAccountOnboardingLink(
    stripeAccountId: string,
  ): Promise<AxiosResponse<Stripe.LoginLink>> {
    const url = `/api/stripe/connect/${stripeAccountId}/onboarding`;
    return Axios({
      method: 'get',
      url,
      params: {},
    });
  }

  static savePaymentMethod(
    stripeCustomerId: string,
    paymentInfo: string,
    isPrimary?: boolean,
  ): Promise<AxiosResponse<Stripe.CustomerSource>> {
    const url = '/api/stripe/paymentMethod';
    return Axios({
      method: 'post',
      url,
      data: {
        stripeCustomerId,
        paymentInfo,
        isPrimary,
      },
    });
  }

  static createCardToken(
    cardData: CardData,
  ): Promise<AxiosResponse<StripeTokenResponse | Stripe.CustomerSource>> {
    const url = '/api/stripe/cardToken';
    return Axios({
      method: 'post',
      url,
      data: cardData,
    });
  }

  static GetStripeOauthUrl(
    companyInformation: any,
    portalConfig: any,
    nextPath?: string,
  ): Promise<AxiosResponse<string>> {
    const { id, businessName } = companyInformation;
    const url = `/api/stripe/oauthUrl`;
    return Axios({
      method: 'get',
      url,
      params: {
        businessUrl: portalConfig.SignoutRedirectURL,
        redirectUri: `${portalConfig.BaseAPIUrl}/users/stripe/token`,
        state: JSON.stringify({
          paymentInfo: { id, businessName, ownerId: portalConfig.portalHeader },
          next:
            !nextPath || nextPath === '/'
              ? window.location.href
              : `${window.location.origin}${nextPath}`,
        }),
        ...companyInformation,
      },
    });
  }

  static UpdatePaymentInfo(
    userId: string,
    paymentInfo: PaymentInfo,
  ): Promise<PaymentInfo> {
    return API.put(UsersAPIName, `/users/${userId}/payment`, {
      body: paymentInfo,
    });
  }

  /**
   * Return the core data needed in the app for this user in a single request
   * @param userId id of the user to get data for
   * @returns core data this user has access to
   */
  static loadAllUserData(userId: string): Promise<LoadUserDataResponse> {
    return API.get(UsersAPIName, `/v1/users/anyType/${userId}/data`, {
      queryStringParameters: { includeArchived: true, ignore: '' },
    });
  }

  /**
   * This will load all items for user data request by iterating over each page
   * @param userId user making request
   * @param currentItems current list of items we have loaded
   * @param nextToken next token to use to get more items
   * @returns
   */
  static async loadPaginatedUserDataItems(
    userId: string,
    queryStringParameters: Record<string, string | boolean>,
    responseDataKey: string,
    currentItems = [],
    nextToken: string | undefined = undefined,
  ): Promise<LoadUserDataResponse> {
    // call user data again and again, until we get all the clients
    const getItemsResponse = await API.get(
      UsersAPIName,
      `/v1/users/anyType/${userId}/data`,
      {
        queryStringParameters: {
          ...queryStringParameters,
          nextToken,
        },
      },
    );

    const responseItems = getItemsResponse.data[responseDataKey] || [];
    const allItems = [...currentItems, ...responseItems];

    if (!getItemsResponse.nextToken) {
      getItemsResponse.data[responseDataKey] = allItems;
      return getItemsResponse;
    }

    return this.loadPaginatedUserDataItems(
      userId,
      queryStringParameters,
      responseDataKey,
      allItems as [],
      getItemsResponse.nextToken,
    );
  }

  /**
   * Return the core data needed in the app for this user in multiple requests.
   * This is a workaround for the 6MB limit on API Gateway responses. The largest
   * parts of the response are uses, notifications, file channels and companies. So this
   * method will make 5 parallel requests. 4 requests for the above data
   * types (using include param) and 1 request for all other data types (use exclude param).
   * If any of the requests fail, the entire request will fail similar to the loadAllUserData method.
   * @param userId id of the user to get data for
   * @returns core data this user has access to
   */
  static async loadUserData(userId: string): Promise<LoadUserDataResponse> {
    const initialDataParts = await Promise.all([
      this.loadPaginatedUserDataItems(
        userId,
        {
          includeArchived: true,
          dataType: 'users',
        },
        'users',
        [],
      ),
      API.get(UsersAPIName, `/v1/users/anyType/${userId}/data`, {
        queryStringParameters: {
          includeArchived: true,
          dataType: 'notifications',
        },
      }),
      API.get(UsersAPIName, `/v1/users/anyType/${userId}/data`, {
        queryStringParameters: {
          includeArchived: true,
          dataType: 'companies',
        },
      }),
      this.loadPaginatedUserDataItems(
        userId,
        {
          includeArchived: true,
          dataType: 'fileChannels',
        },
        'fileChannels',
        [],
      ),
      // get all other data types. In exclude types we specify the data types we already
      // requested in the previous requests
      API.get(UsersAPIName, `/v1/users/anyType/${userId}/data`, {
        queryStringParameters: {
          includeArchived: true,
          excludeTypes: ['users', 'notifications', 'companies', 'fileChannels'],
        },
      }),
    ]);
    const [usersData, notificationsData, companies, fileChannels, otherData] =
      initialDataParts;
    otherData.data.users = usersData.data.users;
    otherData.data.notifications = notificationsData.data.notifications;
    otherData.data.companies = companies.data.companies;
    otherData.data.fileChannels = fileChannels.data.fileChannels;
    return otherData;
  }

  static listUsers() {
    return API.get(UsersAPIName, `/v1/users/internal`, {});
  }

  static listClients() {
    return API.get(UsersAPIName, `/v1/users/clients`, {});
  }

  static GetSettings(userId: string): Promise<GetSettingsResponse> {
    return API.get(UsersAPIName, `/users/${userId}/settings`, {
      queryStringParameters: {},
    });
  }

  static addUser(data: { clientData: ClientFormData; isClient: boolean }) {
    const { clientData, isClient } = data;
    const { cognitoEmail, cognitoFirstName, cognitoLastName, role } =
      clientData;

    const clientUser = {
      givenName: cognitoFirstName,
      familyName: cognitoLastName,
      email: cognitoEmail,
      roles: [role],
    };

    return API.post(
      UsersAPIName,
      `/v1/users/${isClient ? 'clients' : 'internal'}`,
      {
        body: {
          data: [
            {
              additionalFields: { sendInvite: true },
              fields: clientUser,
            },
          ],
        },
      },
    );
  }

  static updateClientUser(clientData: ClientFormData) {
    const {
      userId,
      cognitoEmail,
      cognitoFirstName,
      cognitoLastName,
      companyName,
      companyId,
      avatarImageUrl,
      customFields,
    } = clientData;

    const clientUser = {
      givenName: cognitoFirstName,
      familyName: cognitoLastName,
      email: cognitoEmail,
      companyName,
      avatarImageUrl,
      customFields,
    };

    if (companyId) {
      delete clientUser.companyName;
    }

    return API.put(UsersAPIName, `/v1/users/clients`, {
      body: {
        userId,
        fields: {
          companyId,
          ...clientUser,
        },
      },
    });
  }

  static updateProfileAttributes(userData: {
    userId: string;
    isClient: boolean;
    fields: object;
    userAccessAttributes?: UserAccessAttributes;
  }) {
    const { userId, isClient, fields, userAccessAttributes = {} } = userData;
    return API.put(
      UsersAPIName,
      `/v1/users/${isClient ? 'clients' : 'internal'}`,
      {
        body: {
          userId,
          fields,
          ...userAccessAttributes,
        },
      },
    );
  }

  static setUserRole(data: {
    user: Client;
    roles: Array<UserRolesTypesUnMapped>;
    isClient?: boolean;
  }) {
    const { user, roles, isClient } = data;
    const sendData = {
      ...user,
      fields: {
        ...user.fields,
        roles,
      },
    };

    return API.put(
      UsersAPIName,
      `/v1/users/${isClient ? 'clients' : 'internal'}/anyID/roles`,
      {
        body: {
          data: [
            {
              ...sendData,
            },
          ],
        },
      },
    );
  }

  static deleteUser(user: Client) {
    return API.del(UsersAPIName, `/v1/users/internal`, {
      body: {
        data: [
          {
            id: user.id,
          },
        ],
      },
    });
  }

  // addPassword adds password for the provided user
  // this is done via AdminSetPassword via the backend
  static addPassword({ userId, ...body }: AddPasswordInput, isClient: boolean) {
    return API.post(
      UsersAPIName,
      `/v1/users/${isClient ? 'clients' : 'internal'}/${userId}/addPassword`,
      {
        body,
      },
    );
  }

  static createSubscriptionCheckoutSession(
    priceId: string,
    stripeCustomerId: string,
    quantity: number,
    portalID?: string,
  ): Promise<AxiosResponse<StripeSessionResponse>> {
    const url = '/api/stripe/createSubscriptionCheckoutSession';
    return Axios({
      method: 'post',
      url,
      data: {
        priceId,
        stripeCustomerId,
        portalID,
        quantity,
      },
    });
  }

  static createPaymentMethodCheckoutSession(
    currentStripeCustomerId: string,
    subscriptionId: string,
    portalID?: string,
  ): Promise<AxiosResponse<StripeSessionResponse>> {
    const url = '/api/stripe/createPaymentMethodCheckoutSession';
    return Axios({
      method: 'post',
      url,
      data: {
        stripeCustomerId: currentStripeCustomerId,
        subscriptionId,
        portalID,
      },
    });
  }

  static initiateQuickbooksAuth(
    portalId?: string,
    redirectUrl?: string,
  ): Promise<AxiosResponse<string>> {
    const url = '/api/quickbooks/connect';

    const requestBody: QuickbookAuthRequestBody = {
      portalId,
    };

    // if we have redirect url
    // add it to the request body
    if (redirectUrl) {
      requestBody.redirectUrl = redirectUrl;
    }

    return Axios({
      method: 'post',
      url,
      data: requestBody,
    });
  }

  /**
   * Gets the list of workspaces by user emails
   * @param emails: user emails
   * @returns
   */
  static getWorkspaces(emails: string[]) {
    return API.post(UsersAPIName, `/users/workspaces`, {
      body: {
        emails,
      },
    });
  }

  static getProxyUser(email: string) {
    return API.get(UsersAPIName, `/users/proxy`, {
      responseType: 'text',
      queryStringParameters: {
        email,
      },
    });
  }

  static getCustomAuthChallengeCode(username: string) {
    return API.get(UsersAPIName, `/users/proxy/code`, {
      queryStringParameters: {
        username,
      },
    });
  }

  static async validateEmailDomain(email: string): Promise<any> {
    return API.post(UnAuthAPIName, `/auth/email`, {
      body: { email },
    });
  }
}
