import {
  LandingPagesModel,
  LandingState,
  LandingActionTypes,
  LOAD_LANDINGS_REQUEST,
  LOAD_LANDINGS_DONE,
  LOAD_LANDINGS_FAIL,
  UPDATE_LANDING_REQUEST,
  UPDATE_LANDING_SUCCESS,
  UPDATE_LANDING_FAILURE,
} from 'src/store/landing/types';
import INITIAL_LANDING_PAGE_DATA, {
  PublicSitePages,
} from 'src/constants/landingConsts';

export const initialLandingPageStateData: LandingPagesModel =
  INITIAL_LANDING_PAGE_DATA;

const initialState: LandingState = {
  data: initialLandingPageStateData,
  isLoading: false,
  isLoaded: false,
  isUpdating: false,
  isUpdated: false,
};

/* eslint-disable-next-line default-param-last */
const landingSReducer = (state = initialState, action: LandingActionTypes) => {
  switch (action.type) {
    case LOAD_LANDINGS_REQUEST: {
      return {
        ...state,
        isLoaded: false,
        isLoading: true,
      };
    }

    case LOAD_LANDINGS_DONE: {
      const { payload } = action;
      const stagedData = {
        home: payload.home || initialLandingPageStateData.home,
        team: payload.team || initialLandingPageStateData.team,
        aboutUs: payload.aboutUs || initialLandingPageStateData.aboutUs,
        contactUs: payload.contactUs || initialLandingPageStateData.contactUs,
        pricing: payload.pricing || initialLandingPageStateData.pricing,
      };
      return {
        ...state,
        data: stagedData,
        isLoaded: true,
        isLoading: false,
      };
    }

    case LOAD_LANDINGS_FAIL: {
      return {
        ...state,
        data: initialLandingPageStateData,
        isLoaded: false,
        isLoading: false,
      };
    }

    case UPDATE_LANDING_REQUEST:
      return {
        ...state,
        isUpdated: false,
        isUpdating: true,
      };
    case UPDATE_LANDING_SUCCESS: {
      const { data } = action;
      let stagedData = state.data;
      switch (data.fields.pageEntityType) {
        case PublicSitePages.Home:
          stagedData = {
            ...state.data,
            home: data,
          };
          break;

        case PublicSitePages.About:
          stagedData = {
            ...state.data,
            aboutUs: data,
          };
          break;

        case PublicSitePages.Contact:
          stagedData = {
            ...state.data,
            contactUs: data,
          };
          break;

        case PublicSitePages.Team:
          stagedData = {
            ...state.data,
            team: data,
          };
          break;
        case PublicSitePages.Pricing:
          stagedData = {
            ...state.data,
            pricing: data,
          };
          break;

        default:
          break;
      }
      return {
        ...state,
        data: stagedData,
        isUpdated: true,
        isUpdating: false,
        isWithoutActionOverlay: false,
      };
    }

    case UPDATE_LANDING_FAILURE:
      return {
        ...state,
        isUpdated: false,
        isUpdating: false,
        isWithoutActionOverlay: false,
      };

    default:
      return state;
  }
};

export default landingSReducer;
