import { GridApi } from '@ag-grid-community/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import AgGrid, { AgGridContext, UserCellRenderer } from 'src/components/AgGrid';
import { NotificationTableRowData } from 'src/components/Inbox/InboxContent';
import { NotificationTableActionRenderer } from 'src/components/Inbox/NotificationTableActionRenderer';
import { NotificationTableBreadcrumbRenderer } from 'src/components/Inbox/NotificationTableBreadcrumbRenderer';
import { NotificationTableNameRenderer } from 'src/components/Inbox/NotificationTableNameRenderer';
import { NotificationEventType } from 'src/entities/Notifications';
import { getHumanReadableFileSize } from '../Files/helpers';
import BaseTypography from '../Text/BaseTypography';

interface InboxNotificationTableProps {
  rowData: NotificationTableRowData[];
  notificationEventType: NotificationEventType;
}

const FileSizeRenderer = ({ value: fileSize }: { value: number | undefined }) =>
  // file size can be undefined for deleted files - then we render nothing
  fileSize ? (
    <BaseTypography>{getHumanReadableFileSize(fileSize)}</BaseTypography>
  ) : null;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableWrapper: {
      width: '100%',
      height: '100%',
      [theme.breakpoints.up('sm')]: {
        // intiallay hide the notification table row action button
        '& .notification-table-action': {
          display: 'none',
        },
        // display the notification table row action button while row hover
        '& .ag-row-hover .notification-table-action': {
          display: 'initial',
        },
      },
    },
  }),
);

export const InboxNotificationTable = ({
  rowData = [],
  notificationEventType,
}: InboxNotificationTableProps) => {
  const classes = useStyles();
  const [gridApi, setGridApi] = React.useState<GridApi>();

  const agGridContextValue = React.useMemo(
    () => ({
      gridApi,
      setGridApi,
    }),
    [gridApi, setGridApi],
  );

  const sortComparator = (valA: string, valB: string) => {
    if (!valA || !valB) {
      return 0;
    }

    return valA > valB ? 1 : -1;
  };

  const columns = React.useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'name',
        suppressSizeToFit: true,
        sortable: true,
        sort: 'asc',
        cellRenderer: 'nameCellRenderer',
        comparator: sortComparator,
      },
      {
        headerName: 'Location',
        field: 'location',
        minWidth: 300,
        sortable: true,
        comparator: sortComparator,
        cellRenderer: 'fileLocationRenderer',
        cellRendererParams: {
          useCellData: true,
        },
      },
      notificationEventType === 'files.new' ||
      notificationEventType === 'files.created'
        ? {
            headerName: 'Size',
            field: 'size',
            sortable: true,
            minWidth: 100,
            comparator: sortComparator,
            cellRenderer: 'sizeCellRenderer',
            cellRendererParams: {
              useCellData: true,
            },
          }
        : {},
      {
        headerName:
          notificationEventType === 'files.new' ||
          notificationEventType === 'files.created'
            ? 'From'
            : 'Signed by',
        field: 'userName',
        cellRenderer: 'userCellRenderer',
        minWidth: 280,
        cellRendererParams: {
          useCellData: true,
          fieldKeys: {
            nameField: 'userName',
            avatarField: 'picture',
            hideCellField: 'isDeleted', // we don't want to show User for deleted files
          },
        },
        sortable: true,
        comparator: sortComparator,
      },
      {
        colId: 'actions',
        headerName: 'Actions',
        cellRenderer: 'actionRenderer',
        suppressSizeToFit: true,
        cellRendererParams: {
          useCellData: true,
        },
        cellClass: 'centeredColumn',
        pinned: 'right',
      },
    ],
    [],
  );

  if (!rowData.length) {
    return null;
  }

  return (
    <div className={classes.tableWrapper} data-testid="notification-table">
      <AgGridContext.Provider value={agGridContextValue}>
        <AgGrid
          rowData={rowData}
          columnDefs={columns}
          overlayParams={{
            noRowsLabel: 'No notification',
          }}
          getRowNodeId={(row: NotificationTableRowData) => row.rowId}
          frameworkComponents={{
            nameCellRenderer: NotificationTableNameRenderer,
            userCellRenderer: UserCellRenderer,
            fileLocationRenderer: NotificationTableBreadcrumbRenderer,
            actionRenderer: NotificationTableActionRenderer,
            sizeCellRenderer: FileSizeRenderer,
          }}
          immutableData
        />
      </AgGridContext.Provider>
    </div>
  );
};
