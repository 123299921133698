import React, { useState } from 'react';
import {
  ModuleRegistry,
  GridReadyEvent,
  GridApi,
  ColDef,
  CellClickedEvent,
  RowDragEvent,
  GetRowNodeIdFunc,
} from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { AgGridReact } from '@ag-grid-community/react';
import { withStyles } from '@material-ui/core';
import * as AdGridCss from '../../../externalStyles/Table.scss';
import { BaseMenu } from 'src/components/Dropdowns/BaseMenu';
import HeaderRender from 'src/components/AgGrid/HeaderRender';
import { Loader } from 'src/components/Loading';
import NoDataOverlay, {
  OverlayParams,
} from 'src/components/Overlays/NoDataOverlay';
import NoFilesStandardPage from 'src/components/UI/StandardPage/NoFilesStandardPage';
import { AgGridReactProps } from '@ag-grid-community/react/lib/interfaces';
import { BaseActionsMenuProps } from 'src/constants';

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  CsvExportModule,
  RowGroupingModule,
]);

const baseDefaultColDef = {
  sortable: false,
  suppressMenu: true,
  suppressMovable: true,
};

const isHTMLElement = (el: unknown | HTMLElement): el is HTMLElement =>
  el instanceof HTMLElement;

export interface SortParams {
  field?: string;
  sortable?: boolean;
  sort?: string;
}

export interface TableProps {
  sidebarType?: string;
  animateRows?: boolean;
  immutableData?: boolean;
  rowData?: Array<Record<string, any>>;
  columnDefs: Array<ColDef>;
  onRowDragEnd?: (event: RowDragEvent) => void;
  onRowDragMove?: (event: RowDragEvent) => void;
  onRowDragLeave?: (event: RowDragEvent) => void;
  rowClass?: string;
  suppressNoRowsOverlay?: boolean;
  defaultColDef?: ColDef;
  gridLoaded?: (gridApi: GridApi) => void;
  searchKey?: string;
  enableCellTextSelection?: boolean;
  rowSelection?: string;
  rowHeight?: number;
  headerHeight?: number;
  overlayParams?: OverlayParams;
  pagination?: boolean;
  paginationAutoPageSize?: boolean;
  onRowClicked?: (rowData: any) => void;
  frameworkComponents: any;
  noRowsOverlayComponentName?: string;
  suppressCellSelection?: boolean;
  getRowNodeId?: GetRowNodeIdFunc;
  className?: string;
  onCellClicked?: AgGridReactProps['onCellClicked'];
}

interface AgGridContextType {
  gridApi?: GridApi | null;
  setGridApi: (gridApi?: GridApi) => void;
}

// set the defaults
export const AgGridContext = React.createContext({
  gridApi: null,
  setGridApi: () => {
    console.info('setGridApi not provided');
  },
} as AgGridContextType);

const tableContainerStyle = {
  height: '100%',
  width: '100%',
};

const Table: React.FC<TableProps> = ({
  gridLoaded,
  rowData,
  columnDefs,
  searchKey,
  onRowClicked,
  frameworkComponents,
  noRowsOverlayComponentName = '',
  defaultColDef = baseDefaultColDef,
  sidebarType = 'noSidebar',
  enableCellTextSelection = true,
  rowSelection = 'multiple',
  rowHeight = 50,
  headerHeight = 40,
  overlayParams = {
    noRowsLabel: 'No data is added',
  },
  ...tableProps
}) => {
  const gridTableApi = React.useRef<GridApi>();
  const [actionMenuButtonProps, setActionMenuButtonProps] =
    useState<BaseActionsMenuProps | null>(null);
  const { setGridApi, gridApi } = React.useContext(AgGridContext);

  const handleMenuClose = () => {
    setActionMenuButtonProps(null);
  };

  const handleActionMenuClick = (menuOptions: any) => {
    setActionMenuButtonProps({
      ...menuOptions,
      menuProps: {
        ...menuOptions.menuProps,
        onClose: handleMenuClose,
      },
    });
  };

  const formattedColDefs = React.useMemo(
    () =>
      columnDefs.map((col) => {
        if (col.headerName === 'Actions') {
          return {
            width: 120,
            headerClass: 'hiddenHeaderCell',
            cellClass: 'centeredColumn',
            ...col,
            cellRendererParams: {
              onActionMenuClick: handleActionMenuClick,
              ...col.cellRendererParams,
            },
          };
        }

        if (col.headerName === 'Creation date') {
          return {
            headerClass: 'centeredHeaderCell',
            ...col,
          };
        }

        return col;
      }),
    [columnDefs],
  );

  React.useEffect(() => {
    gridApi?.setQuickFilter(searchKey);
  }, [searchKey, gridApi]);

  const handleResize = () => {
    setTimeout(() => {
      if (gridTableApi.current) {
        gridTableApi.current.sizeColumnsToFit();
      }
    });
  };

  const onGridReady = (params: GridReadyEvent) => {
    gridTableApi.current = params.api;
    setGridApi(params.api);
    window.addEventListener('resize', handleResize);
    params.api.sizeColumnsToFit();
    if (gridLoaded) {
      return gridLoaded(params.api);
    }
    return null;
  };

  const mappedFrameworkComponents = {
    ...frameworkComponents,
    agColumnHeader: HeaderRender,
    customLoadingOverlay: Loader,
    customNoRowsOverlay: NoDataOverlay,
    // eslint-disable-next-line react/no-unstable-nested-components
    NoFilesStandardPage: () => (
      <NoFilesStandardPage actionButton={overlayParams.actionButton} />
    ),
  };

  const baseColDef = { ...baseDefaultColDef, ...defaultColDef };
  let sideBarDef;
  if (sidebarType === 'columnBasic') {
    sideBarDef = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressSideButtons: true,
            suppressColumnFilter: true,
            suppressColumnSelectAll: true,
            suppressColumnExpandAll: true,
          },
        },
      ],
    };
  }

  const handleCellClicked = (event: CellClickedEvent) => {
    if (!onRowClicked) {
      return;
    }

    if (event.colDef.headerName !== 'Actions') {
      // if this is not the actions column which has its own click handler
      onRowClicked(event.data);
    }
  };

  /**
   * fit columns when changed
   */
  const handleColumnsChanged = () => {
    if (gridTableApi.current) {
      gridTableApi.current.sizeColumnsToFit();
    }
  };

  const handleScroll = (e: Event) => {
    let timeout: ReturnType<typeof setTimeout>;
    const el = isHTMLElement(e.target) ? e.target : null;
    if (el && !el.classList.contains('on-scrollbar')) {
      el.classList.add('on-scrollbar');
      timeout = setTimeout(() => {
        el.classList.remove('on-scrollbar');
      }, 1500);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('scroll', handleScroll, true);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="ag-theme-portal" style={tableContainerStyle}>
      <AgGridReact
        rowHeight={rowHeight}
        headerHeight={headerHeight}
        defaultColDef={baseColDef}
        frameworkComponents={mappedFrameworkComponents}
        columnDefs={formattedColDefs}
        sideBar={sideBarDef}
        onGridReady={onGridReady}
        suppressScrollOnNewData
        suppressAggFuncInHeader
        noRowsOverlayComponent={
          noRowsOverlayComponentName || 'customNoRowsOverlay'
        }
        loadingOverlayComponent="customLoadingOverlay"
        noRowsOverlayComponentParams={{
          overlayParams: () => overlayParams,
        }}
        suppressContextMenu
        rowData={rowData}
        rowSelection={rowSelection}
        enableCellTextSelection={enableCellTextSelection}
        onCellClicked={handleCellClicked}
        onGridColumnsChanged={handleColumnsChanged}
        animateRows
        suppressRowClickSelection
        {...tableProps}
      />
      {actionMenuButtonProps && <BaseMenu {...actionMenuButtonProps} />}
    </div>
  );
};
export default withStyles(AdGridCss)(Table);
