import Stripe from 'stripe';

import { UserFields } from 'src/constants/dataTypes/userTypes';

export interface BaseEntity {
  id: string;
  ownerId: string;
  identityId: string;
  owner: User | null;
  createdDate: string;
  updatedDate: string;
  entityStatus: string;
  getstreamId: string;
  ref?: string;
  metadata: Record<string, any>;
}

export interface OnboardingStatus {
  id: string;
  object: string;
  createdAt: string;
  updatedAt: string;
  fields: {
    dismissed: boolean;
    creditsAwarded: boolean;
    customizationUpdateDate: string | null;
    firstClientCreatedDate: string | null;
    customDomainAddedDate: string | null;
    connectBankAccountDate: string | null;
    firstAppAddAccountDate: string | null;
  };
}

export interface User extends BaseEntity {
  fields: UserFields;
  cognitoUsername?: string;
  additionalFields: {
    formsAssociated?: string[];
    assignedToCompanies?: Map<string, boolean>;
    leadOfCompanies?: Map<string, boolean>;
  };
  isClientAccessLimited?: boolean;
  companyAccessList?: string[];
}

export type Client = User;

export interface Company extends BaseEntity {
  fields: {
    name: string;
    avatarImageURL?: string;
    fallbackColor?: string;
    primaryDomain: string;
    associatedDomains: string[];
    customerId: string;
    isPlaceholder: boolean;
    leadUserID?: string;
  };
  additionalFields?: {};
}

export interface MessageSettings extends BaseEntity {
  fields: {
    messageBody: string;
    isSecure: boolean;
    senderUserId: string;
    isWelcomeMessageDisabled: boolean;
  };
  creatorId: string;
}

export interface FieldVisibility {
  id: string;
  name: string;
  disabled: boolean;
}

export interface TablePropertyFields {
  tablePropertyFields: FieldVisibility[];
}

export interface TableProperties extends BaseEntity {
  creatorId: string;
  updatedBy: string;
  structFields?: TablePropertyFields;
}

export interface WebhookConfigStructFields {
  authKey: string;
  destinationURL: string;
  event: string;
}
export interface WebhookConfigSetStructFields {
  destinationURL: string;
  authKey: string;
  label: string;
  description: string;
  configIDs?: string[];
  configs: WebhookConfigStructFields[];
}

export interface WebhookConfigSet extends BaseEntity {
  structFields?: WebhookConfigSetStructFields;
}

export interface CreatedItem extends BaseEntity {
  fields: {
    companyId?: string;
    associatedDomains?: Array<string>;
    name?: string;
    primaryDomain?: string;
  };
}

export type StripeInvoice = Stripe.Invoice;

type ResourceContent = {
  title: string;
  body: string;
  button_primary: string;
  button_secondary: string;
  action_primary: string;
  action_secondary: string;
  asset: string;
  thumbnail: string;
};

export enum ResourceDismissalType {
  manual_organization = 'manual - organization',
  manual_individual = 'manual - individual',
  automatic = 'automatic',
}

type ResourceDismissal = {
  scope: string;
  type: ResourceDismissalType;
};

export type ResourceVisibility = {
  hide_on_mobile: boolean;
};
export type RecommendedResource = {
  CardUUID: string;
  content: ResourceContent;
  dismissal: ResourceDismissal;
  visibility: ResourceVisibility;
};
export interface RecommendedResourceData extends BaseEntity {
  structFields: Array<RecommendedResource>;
}

export interface ResourceDismissalData extends BaseEntity {
  structFields: {
    dismissed: Record<string, boolean>;
  };
}

export interface LatestReleaseDismissData extends BaseEntity {
  structFields: {
    latest_release_dismissed: string;
  };
}

export enum ReferralStatus {
  Invited = '0',
  In_Trial = '1',
  Signed_Up = '2',
  Expired = '3',
}

export type Referral = {
  amount: number;
  createdAt: string;
  id: string;
  onboardedUserEmail: string;
  onboardedUserName: string;
  status: ReferralStatus;
  updatedAt: string;
};
