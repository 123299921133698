import Stripe from 'stripe';
import moment from 'moment';
import { SeverityLevel } from 'src/components/UI';
import {
  InvoiceEntity,
  InvoiceLineItem,
  InvoiceAdditionalFields,
  InvoicePaymentFees,
} from 'src/store/payments/types';
import { PAYMENT_FEE, SubscriptionStatus } from 'src/constants';
import {
  AutomaticInvoiceSchema,
  LineItemsSchema,
  ManualInvoiceSchema,
} from 'src/legacy/components/billing/new/types';
import {
  Invoice,
  InvoiceCollectionMethod,
  InvoiceStatus,
} from 'src/services/api/invoicesApi';
import { FileUtils, S3Utils } from 'src/utils';
import { StripePlanInfo } from 'src/utils/BillingUtils';

export default class InvoiceUtils {
  static MapInvoiceStatusToServeity = (status: string): SeverityLevel => {
    let severityLevel: SeverityLevel = SeverityLevel.low;
    switch (status.toLowerCase()) {
      case 'open':
        severityLevel = SeverityLevel.medium;
        break;
      case 'draft':
        severityLevel = SeverityLevel.neutral;
        break;
      case 'paid':
        severityLevel = SeverityLevel.low;
        break;
      case 'void':
        severityLevel = SeverityLevel.high;
        break;
      case 'uncollectible':
        severityLevel = SeverityLevel.high;
        break;
      default:
        break;
    }
    return severityLevel;
  };

  /**
   * This methods get subtotals for subscriptions line items
   * The difference between this method and GetSubTotalOfLineitem is that this method
   * uses the legacy amount field for the line item.
   * @param lineItems
   * @returns number
   */
  static GetSumOfSubtotals = (lineItems: InvoiceLineItem[]) =>
    lineItems.reduce((sum, currentItem) => {
      const itemTotal = currentItem.quantity * Number(currentItem.rate ?? 0);
      const itemTotalInCents = convertAmountToCents(itemTotal);
      const roundedItemTotal = Number(itemTotalInCents.toFixed(2)) / 100;
      return sum + roundedItemTotal;
    }, 0);

  static GetACHFeeLabel = (amount: number) => {
    if (amount / 100.0 > PAYMENT_FEE.LIMIT) {
      return `$${PAYMENT_FEE.MAX_ACH_FEE}`;
    }
    return `${PAYMENT_FEE.BANK_DEFAULT}%`;
  };

  static GetACHFee = (amount: number) => {
    if (amount / 100.0 > PAYMENT_FEE.LIMIT) {
      return PAYMENT_FEE.MAX_ACH_FEE * 100;
    }
    return amount * (PAYMENT_FEE.BANK_DEFAULT / 100.0);
  };

  static GetSubTotalOfLineitem = (lineItems: LineItemsSchema) =>
    lineItems.reduce((sum, currentItem) => {
      const itemTotal =
        Number(currentItem.quantity) * Number(currentItem.amount ?? 0);
      const itemTotalInCents = convertAmountToCents(itemTotal);
      const roundedItemTotal = Number(itemTotalInCents.toFixed(2)) / 100;
      return sum + roundedItemTotal;
    }, 0);

  static GetDueDate = (invoiceFields: Stripe.Invoice | undefined) => {
    if (invoiceFields && invoiceFields.due_date) {
      return moment.unix(invoiceFields.due_date).format('MMM DD, YYYY');
    }
    if (invoiceFields && invoiceFields.due_date === 0) {
      return 'Today';
    }

    return '';
  };

  static GetTotalAmount = (
    lineItems: LineItemsSchema,
    taxPercentage: string,
  ) => {
    const subTotal = InvoiceUtils.GetSubTotalOfLineitem(lineItems).toString();
    const taxAmount =
      !!subTotal && !!taxPercentage
        ? parseFloat(subTotal) * (parseFloat(taxPercentage) / 100.0)
        : 0;

    // total amount is always in dollars
    return subTotal ? parseFloat(subTotal) + taxAmount : 0;
  };

  /**
   * This method checks if an invoice is issued from a subscription
   * @param invoiceData
   */
  static IsSubscriptionCreatedInvoice = (invoiceData: InvoiceEntity) =>
    [
      'subscription_create',
      'subscription_cycle',
      'subscription_update',
    ].includes(invoiceData?.additionalFields?.billing_reason || '');

  static IsStripeSubscription = (
    sub?: string | Stripe.Subscription | null,
  ): sub is Stripe.Subscription => typeof sub !== 'string';

  static IsOpenSubscriptionInvoice = (
    invoiceData?: Stripe.Invoice | InvoiceAdditionalFields,
  ) =>
    Boolean(
      invoiceData &&
        invoiceData.billing_reason === 'subscription_create' &&
        this.IsStripeSubscription(invoiceData.subscription)
        ? invoiceData.subscription?.status !== SubscriptionStatus.Canceled
        : false,
    );

  static calculatePaymentFees = (
    feeDetails: InvoicePaymentFees | undefined,
    absorbTransactionFees: boolean,
    legacyFeeAmount: number | null,
  ) => {
    let clientPaymentFee = null;
    let paymentFee = null;

    /*
     feeDetails will be null when
      a. for non-paid invoices
      b. for legacy paid invoices
     for these cases we need show legacyFeeAmount
     based on if the transaction fee is absorbed or not
    */
    if (!feeDetails) {
      if (absorbTransactionFees) {
        paymentFee = legacyFeeAmount;
      } else {
        clientPaymentFee = legacyFeeAmount;
      }

      return {
        clientPaymentFee,
        paymentFee,
      };
    }

    const {
      fee_credit_international_perc_amount,
      fee_credit_conversion_perc_amount,
      fee_recurring_perc_amount,
      fee_ach_fixed_amount,
      fee_ach_perc_amount,
      fee_credit_base_perc_amount,
      fee_credit_fixed_amount,
    } = feeDetails;

    // These fees are always paid by internal user
    paymentFee =
      fee_credit_international_perc_amount +
      fee_credit_conversion_perc_amount +
      fee_recurring_perc_amount;

    clientPaymentFee = 0;

    const absorbableFee =
      fee_ach_fixed_amount +
      fee_ach_perc_amount +
      fee_credit_base_perc_amount +
      fee_credit_fixed_amount;

    // if internal user is absorbing payment fees
    // then add absorbable fee into paymentFee
    // else that fee will be paid by client
    // so add absorbable fee into clientPaymentFee
    if (absorbTransactionFees) {
      paymentFee += absorbableFee;
    } else {
      clientPaymentFee += absorbableFee;
    }

    return {
      clientPaymentFee,
      paymentFee,
    };
  };
}

/**
 *
 * @param amount - number
 * @description - Amount in invoices is kept in cents, they are also prone to floating point errors
 * so we need to round off and convert them to cents before sending to stripe
 * @returns
 */
export const convertAmountToCents = (amount: number) => {
  return Math.round(amount * 100);
};

/**
 *
 * @param existingInvoice {Invoice}
 * @description invoiceEntityToInvoiceSchema will take an existing invoice entity and transform it into InvoiceSchema
 * InvoiceSchema is used by create and update methods for invoices
 */
export const invoiceEntityToInvoiceSchema = (
  existingInvoice: Invoice,
): ManualInvoiceSchema | AutomaticInvoiceSchema => {
  const isAutoChargeInvoice =
    existingInvoice.collectionMethod ===
    InvoiceCollectionMethod.ChargeAutomatically;

  const defaultSchemaValues = {
    recipientId: existingInvoice.recipientId,
    taxPercentage: existingInvoice.taxPercentage.toString(),
    attachmentKeys: existingInvoice.attachmentKeys,
    memo: existingInvoice.memo,
    lineItems: existingInvoice.lineItems,
    paymentPreferences: existingInvoice.paymentPreferences,
    totalAmount: existingInvoice.total,
    status: InvoiceStatus.Draft,
    currency: existingInvoice.currency,
  };

  if (isAutoChargeInvoice) {
    const payload: AutomaticInvoiceSchema = {
      ...defaultSchemaValues,
      collectionMethod: InvoiceCollectionMethod.ChargeAutomatically,
      primarySource: '',
    };
    return payload;
  } else {
    const existingInvoiceIssue = moment(existingInvoice.createdAt);
    const existingInvoiceDue = moment(existingInvoice.dueDate);
    const existingInvoiceDueDateCount = existingInvoiceDue.diff(
      existingInvoiceIssue,
      'days',
      true,
    );
    const dueDate = moment()
      .add(Math.round(existingInvoiceDueDateCount), 'days')
      .toISOString();

    const payload: ManualInvoiceSchema = {
      ...defaultSchemaValues,
      collectionMethod: InvoiceCollectionMethod.SendInvoice,
      dueDate: dueDate,
      dueDays: '',
      primarySource: null,
    };
    return payload;
  }
};

/**
 * This method will handle the download of invoice
 * @param param.fileKey - string - fileKey of the invoice
 * @param param.invoiceNumber - string - invoice number is used to name the file
 */
export const handleDownloadInvoice = async ({
  fileKey,
  invoiceNumber,
}: {
  fileKey: string;
  invoiceNumber: string;
}) => {
  const response = await S3Utils.downloadFileWithSignedKey(
    fileKey,
    '/v0/invoices/download/signedUrl',
  );

  const fileName = `Invoice-${invoiceNumber}`;
  FileUtils.downloadFile(response.data, fileKey, fileName);
};

export const downloadInvoiceReceipt = async ({
  receiptKey,
  receiptNumber,
}: {
  receiptKey: string;
  receiptNumber: string;
}) => {
  const response = await S3Utils.downloadFileWithSignedKey(
    receiptKey,
    '/v0/invoices/download/signedUrl',
  );

  const fileName = `Receipt-${receiptNumber}`;
  FileUtils.downloadFile(response.data, receiptKey, fileName);
};

export const getIntervalFromPlan = (plan: StripePlanInfo) => {
  if (plan.interval === 'month' && plan.intervalCount === 3) {
    return 'quarter';
  }
  if (plan.interval === 'month' && plan.intervalCount === 6) {
    return 'biannual';
  }

  return plan.interval;
};
