import { Action, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';
import { UiState } from 'src/store/ui/types';
import { UsersState } from 'src/store/users/types';
import { ClientsState } from 'src/store/clients/types';
import { UserState } from 'src/store/user/types';
import { DataState } from 'src/store/data/types';
import { SettingsState } from 'src/store/settings/types';
import { FilesState } from 'src/store/files/types';
import { PaymentsState } from 'src/store/payments/types';
import { MessagesState } from 'src/store/messages/types';
import { LandingState } from 'src/store/landing/types';
import { NotificationsState } from 'src/store/notifications/types';
import { SignaturePageState } from 'src/store/signaturePage/types';
import { DashboardState } from 'src/store/dashboard/types';
import { KnowledgeBaseState } from 'src/store/knowledgeBase/types';
import { TagsState } from 'src/store/tags/types';
import { TablePropertiesState } from 'src/store/tableProperties/types';
import { ShortcutsState } from 'src/store/shortcuts/slice';
import { AuthState } from 'src/store/auth/slice';
import { entityImportSlice } from 'src/store/entityImporter/slice';
import { appAPI } from 'src/services/api';

export type RootState = {
  user: UserState;
  users: UsersState;
  clients: ClientsState;
  ui: UiState;
  data: DataState;
  settings: SettingsState;
  files: FilesState;
  payments: PaymentsState;
  messages: MessagesState;
  landings: LandingState;
  notifications: NotificationsState;
  // runtime,
  signaturePage: SignaturePageState;
  tags: TagsState;
  knowledgeBase: KnowledgeBaseState;
  dashboard: DashboardState;
  tableProperties: TablePropertiesState;
  shortcuts: ShortcutsState;
  auth: AuthState;
  entityImports: entityImportSlice;
  // redux persist
  _persist: {
    version: number;
    rehydrated: boolean;
  };
  appAPI: ReturnType<typeof appAPI.reducer>;
};

export type AppThunkAction<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action
>;

export type AppThunkDispatch = ThunkDispatch<RootState, unknown, Action>;

export interface BaseItemsState<T> {
  items: T[];
  itemsLoading: boolean;
  itemsLoaded: boolean;
  selectedItemId: string;
  selectedItemType?: string;
  selectedItemTitle?: string;
  loadingError: string;
  creatingItem: boolean;
  createError: string;
}

export enum BaseItemsActionTypes {
  GET_ITEMS = 'GET_ITEMS',
  GET_ITEMS_SUCCESS = 'GET_FILES_SUCCESS',
  GET_ITEMS_ERROR = 'GET_ITEMS_ERROR',
  CREATE_ITEMS_ERROR = 'CREATE_ITEMS_ERROR',
  SET_SELECTED_ITEM = 'SET_SELECTED_ITEM',
  CREATING_ITEM = 'CREATING_ITEM',
}

export const startGetItemsAction = () => ({
  type: BaseItemsActionTypes.GET_ITEMS,
});

export const getItemsErrorAction = (error: string) => ({
  type: BaseItemsActionTypes.GET_ITEMS_ERROR,
  error,
});

export const creatingItemAction = () => ({
  type: BaseItemsActionTypes.CREATING_ITEM,
});

export const createItemErrorAction = (error: string) => ({
  type: BaseItemsActionTypes.CREATE_ITEMS_ERROR,
  error,
});

export interface SetSelectedItemActionOptions {
  itemType?: string;
  title?: string;
}
export const setSelectedItemAction = (
  itemId: string,
  options?: SetSelectedItemActionOptions,
) => ({
  type: BaseItemsActionTypes.SET_SELECTED_ITEM,
  itemId,
  options,
});

export interface StartGetItemsActionType {
  type: typeof BaseItemsActionTypes.GET_ITEMS;
}

export interface GetItemsErrorActionType {
  type: typeof BaseItemsActionTypes.GET_ITEMS_ERROR;
  error: string;
}

export interface CreateItemActionType {
  type: typeof BaseItemsActionTypes.CREATING_ITEM;
}

export interface CreateItemErrorActionType {
  type: typeof BaseItemsActionTypes.CREATE_ITEMS_ERROR;
  error: string;
}

export interface SetSelectedItemActionType {
  type: typeof BaseItemsActionTypes.SET_SELECTED_ITEM;
  itemId: string;
  options?: SetSelectedItemActionOptions;
}

export type BaseItemActions =
  | StartGetItemsActionType
  | GetItemsErrorActionType
  | SetSelectedItemActionType
  | CreateItemActionType
  | CreateItemErrorActionType;

export const BaseItemsInitialState = {
  items: [],
  itemsLoading: false,
  itemsLoaded: false,
  selectedItemId: '',
  selectedItemType: '',
  loadingError: '',
  creatingItem: false,
  createError: '',
};
