import { Dispatch } from 'redux';
import { isContractEntity } from 'src/components/Contracts/contract_helpers';
import { ENTITIES } from 'src/constants/entityConsts';
import { Events } from 'src/constants/webSocketConsts/events';
import { FlagContextType, PortalConfig } from 'src/context';
import { WebSocketMutations } from 'src/hooks/useWebSocketMutations';
import { NotificationGroups } from 'src/store/notifications/types';
import ArticleHandler from 'src/websocket/ArticleHandler';
import channelHandler from 'src/websocket/ChannelHandler';
import clientHandler from 'src/websocket/ClientHandler';
import companyHandler from 'src/websocket/CompanyHandler';
import CustomFieldsHandler from 'src/websocket/CustomFieldsHandler';
import ExtensionItemHandler from 'src/websocket/ExtensionItemHandler';
import fileHandler from 'src/websocket/FileHandler';
import formRequestNotificationHandler from 'src/websocket/FormRequestNotificationHandler';
import { FormsV2ResponseHandler } from 'src/websocket/FormsV2ResponseHandler';
import InternalUserHandler from 'src/websocket/InternalUserHandler';
import invoiceLegacyHandler from './InvoiceLegacyHandler';
import KnowledgeBaseSettingsHandler from 'src/websocket/KnowledgeBaseSettingsHandler';
import { MessageSettingsHandler } from 'src/websocket/MessageSettingsHandler';
import newInvoiceNotificationHandler from 'src/websocket/NewInvoiceNotificationHandler';
import { FileNotificationsHandler } from 'src/websocket/NotificationsHandler';
import { OnboardingTaskStatusHandler } from 'src/websocket/OnboardingTaskStatusHandler';
import PortalSettingsHandler from 'src/websocket/PortalSettingsHandler';
import subscriptionHandler from 'src/websocket/SubscriptionHandler';
import TagHandler from 'src/websocket/TagHandler';
import FormsTemplatesV2Handler from 'src/websocket/formsTemplatesV2Handler';
import { handleInboxNotifications } from 'src/websocket/inboxNotificationHandler';
import paymentHandler from 'src/websocket/paymentHandler';
import { handleContractUpdate } from './contractHandler';
import { handleContractTemplateUpdate } from './contractTemplateHandler';
import { handleProductUpdate } from './productHandler';
import { handlePriceUpdate } from './priceHandler';
import { handleInvoiceUpdate } from './invoiceHandler';
import { RootState } from 'src/store';
import { handleAutomationRunsUpdate } from 'src/websocket/AutomationRunHandler';
import { clientCompanyInvoiceHandler } from 'src/websocket/InvoiceCompanyHandler';
import { handleAutomationUpdate } from 'src/websocket/automationHandler';
import { Automation } from 'src/types/automations';
import {
  AnalyticsEventsAggregateHandler,
  AnalyticsRecentlyVisitedHandler,
} from 'src/websocket/Analytics';
import { customerSubscriptionUpdate } from 'src/websocket/customerSubscriptionHandler';

export interface MsgEvent {
  entity: string;
  event: Events;
  items: Array<any>;
}

function isAutomation(item: any): item is Automation {
  return 'name' in item && 'steps' in item && 'trigger' in item;
}

export const wsMessageHandler = (
  msgEvent: MsgEvent,
  portalConfig: PortalConfig,
  userID: string,
  webSocketMutations: WebSocketMutations,
  _flags: FlagContextType,
  dispatch: Dispatch<any>,
  store: RootState,
) => {
  const { entity, event, items } = msgEvent;
  // forms response ws item entity string
  // is a combination of the entity name and the formResponseId
  // so we need to check if the entity string includes the form response
  // entity name.
  if (entity.includes(ENTITIES.FORM_RESPONSE)) {
    return FormsV2ResponseHandler(event, items, dispatch);
  }

  // skey for CONTRACT is in format CONTRACT:{contract_template_id}:{unique_uuid}
  // so instead of exact match, we are do inclusion of CONTRACT entity name in skey
  if (isContractEntity(entity)) {
    return handleContractUpdate(event, items, dispatch);
  }

  switch (entity) {
    case ENTITIES.FILE_METADATA:
      return fileHandler(event, items);
    case ENTITIES.STRIPE_INVOICE:
      return invoiceLegacyHandler(event, items);
    case ENTITIES.STRIPE_SUBSCRIPTION:
      return subscriptionHandler(event, items);
    case ENTITIES.FILE_CHANNEL:
      return channelHandler(event, items);
    case ENTITIES.CLIENT_USER:
      dispatch(clientHandler(event, items, portalConfig, userID));
      return clientCompanyInvoiceHandler(store, dispatch);

    case ENTITIES.INTERNAL_USER:
      return InternalUserHandler(event, items, portalConfig, userID);
    case ENTITIES.CLIENT_COMPANY:
      return companyHandler(event, items);
    case ENTITIES.PAYMENT_INFO:
      return paymentHandler(event, items);
    case ENTITIES.NOTIFY_NEW_INVOICE:
      dispatch(handleInboxNotifications(items, event));
      return newInvoiceNotificationHandler(event, items);
    case ENTITIES.NOTIFY_FILE_UPLOADED:
      dispatch(handleInboxNotifications(items, event));
      return FileNotificationsHandler(
        NotificationGroups.FileNotifications,
        event,
        items,
        // allow marking notification as read only when
        // user is a client
        portalConfig.viewMode === 'client',
      );
    case ENTITIES.NOTIFY_FILE_ESIGN_REQUEST:
      dispatch(handleInboxNotifications(items, event));
      return FileNotificationsHandler(
        NotificationGroups.EsigRequestNotifications,
        event,
        items,
      );
    case ENTITIES.NOTIFY_FILE_ESIGN_REQUEST_COMPLETE:
      FileNotificationsHandler(
        NotificationGroups.EsigRequestCompleteNotifications,
        event,
        items,
      );
      return handleInboxNotifications(items, event);
    case ENTITIES.NOTIFY_FORM_REQUEST:
      dispatch(formRequestNotificationHandler(event, items));
      return handleInboxNotifications(items, event);

    case ENTITIES.NOTIFY_FORM_COMPLETE:
      return handleInboxNotifications(items, event);
    case ENTITIES.FORM_TEMPLATE:
      return FormsTemplatesV2Handler(event, items);
    case ENTITIES.DOMAIN_SETTINGS:
      // invalidate cache for recommended resources on domain connected event, so that refreshed data is fetched when user user go to home page
      webSocketMutations.invalidateRecommendedResources();
      webSocketMutations.setVerifiedDomains(items);
      break;
    case ENTITIES.REFERRAL:
      // update the cache for referral
      webSocketMutations.invalidateReferrals();
      break;
    case ENTITIES.PORTAL_SETTINGS:
      return PortalSettingsHandler(event, items);
    case ENTITIES.MESSAGE_SETTINGS:
      return MessageSettingsHandler(event, items);
    case ENTITIES.CLIENT_CUSTOM_FIELDS:
      return CustomFieldsHandler(event, items);
    case ENTITIES.EXTENSION_ITEM:
      return ExtensionItemHandler(event, items);
    case ENTITIES.TAG:
      return TagHandler(event, items);
    case ENTITIES.ARTICLE:
      return ArticleHandler(event, items);
    case ENTITIES.KNOWLEDGE_BASE_SETTINGS:
      return KnowledgeBaseSettingsHandler(event, items);
    case ENTITIES.PORTAL_CONFIG:
      if (portalConfig.updatePortalConfig) {
        // portal config by default does not have update method
        // this gets initialized in App.tsx and passed to websocket handler
        portalConfig.updatePortalConfig(items.at(0).fields);
      }
      break;
    case ENTITIES.NOTIFICATION_GROUPING:
    case ENTITIES.NOTIFY_CUSTOM_APP_ACTION:
    case ENTITIES.NOTIFY_INVOICE_PAID:
      return handleInboxNotifications(items, event);
    case ENTITIES.ONBOARDING_TASK_STATUS:
      return OnboardingTaskStatusHandler(items.at(0));
    case ENTITIES.CONTRACT_TEMPLATE:
      return handleContractTemplateUpdate(event, items, dispatch);
    case ENTITIES.PRODUCT:
      return handleProductUpdate(event, items, dispatch);
    case ENTITIES.PRICE:
      return handlePriceUpdate(event, items, dispatch);
    case ENTITIES.INVOICE:
      return handleInvoiceUpdate(event, items, dispatch, store);
    case ENTITIES.AUTOMATION_RUN:
      return handleAutomationRunsUpdate(event, items, dispatch);
    case ENTITIES.AUTOMATION: {
      items.forEach((automation) => {
        if (isAutomation(automation)) {
          handleAutomationUpdate(event, automation, dispatch);
        }
      });
      return;
    }
    case ENTITIES.RECENTLY_VISITED: {
      return AnalyticsRecentlyVisitedHandler(dispatch);
    }
    case ENTITIES.ANALYTICS_EVENTS_AGGREGATE: {
      return AnalyticsEventsAggregateHandler(items, dispatch);
    }
    case ENTITIES.CUSTOMER_SUBSCRIPTION: {
      return customerSubscriptionUpdate(dispatch);
    }

    default:
      break;
  }
  return null;
};
