import React, { ComponentProps, useContext } from 'react';
import { Box, Checkbox, InputAdornment, Theme } from '@material-ui/core';
import Autocomplete, {
  AutocompleteProps,
  AutocompleteClassKey,
} from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { createStyles, makeStyles } from '@material-ui/styles';
import { UserAvatar } from 'src/components/User';
import { ListboxComponent } from 'src/components/Select/ListBox';
import { BaseTextField, TextFieldSizeVariant } from 'src/components/TextField';
import { ChevronDownIcon } from 'src/components/Icons';
import {
  BlackHeadings,
  DividersAndCardBorders,
  GraySmall,
  HoverBackground,
  NonHoverBorder,
  black,
} from 'src/theme/colors';
import { BaseChip } from 'src/components/UI';
import BaseTypography, {
  typography12MediumStyle,
} from 'src/components/Text/BaseTypography';

import { DropdownMenuShadow } from 'src/theme/shadows';
import { AvatarSizeType } from 'src/components/User/UserAvatar';
import clsx from 'clsx';
import {
  GroupListboxComponent,
  RenderGroup,
} from 'src/components/Select/GroupListBox';
import { FlagsContext } from 'src/context';
import { useFormikContext } from 'formik';

type StyleProps = {
  disabled?: boolean;
  EnableSubscriptionRedesign: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles<string, StyleProps>({
    list: {
      padding: theme.spacing(0.75, 0),
    },
    groupLabel: {
      color: GraySmall,
      textTransform: 'capitalize',
      ...typography12MediumStyle,
    },
    dropdownIcon: {
      height: '15px',
      width: '15px',
      color: ({ disabled }) => (disabled ? NonHoverBorder : GraySmall),
    },
    listPaper: {
      border: `1px solid ${NonHoverBorder}`,
      boxShadow: DropdownMenuShadow,
      '& .MuiAutocomplete-groupUl': {
        '& .MuiAutocomplete-option': {
          paddingLeft: theme.spacing(2),
        },
      },
    },
    inputRoots: {
      '& .MuiAutocomplete-endAdornment': {
        marginRight: theme.spacing(0.25),
      },
    },
    label: {
      '&.MuiTextField-root label.MuiInputLabel-outlined': {
        '&.Mui-disabled': {
          color: NonHoverBorder,
        },
      },
      '&.MuiTextField-root .MuiOutlinedInput-root.Mui-disabled': {
        color: NonHoverBorder,
      },
      '& .MuiInputBase-input': {
        fontSize: ({ EnableSubscriptionRedesign }) =>
          EnableSubscriptionRedesign ? '14px' : 'inherit',
        [theme.breakpoints.down('xs')]: {
          fontSize: ({ EnableSubscriptionRedesign }) =>
            EnableSubscriptionRedesign ? '14px' : '16px',
        },
      },
    },
    root: {
      borderRadius: 100,
      color: BlackHeadings,
      backgroundColor: HoverBackground,
      border: `1px solid ${NonHoverBorder}`,
      padding: theme.spacing(0.5, 1, 0.5, 0),
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: DividersAndCardBorders,
      },
      '&.MuiChip-root': {
        height: 'auto',
        '& span': {
          padding: 0,
        },
      },
      '&.MuiAutocomplete-tag': {
        margin: 0,
      },
    },
    closeIcon: {
      height: 8,
      width: 8,
      color: GraySmall,
      padding: theme.spacing(0.5),
      marginLeft: '2px',
      '&:hover': {
        color: BlackHeadings,
        background: NonHoverBorder,
        borderRadius: '50%',
      },
      margin: 0,
    },
    avatarContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    companiesAvatarContainer: {
      marginLeft: theme.spacing(1.25),
    },
    clientsAvatarContainer: {
      marginLeft: theme.spacing(1),
    },
    labelContainer: {
      paddingLeft: theme.spacing(1.25),
    },
    chipContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(0.625),
      padding: '5px 0',
    },
    chipLabel: {
      flexShrink: 0,
    },
    adornment: {
      '&.MuiInputAdornment-positionStart ': {
        marginRight: 'unset',
        marginLeft: '5px',
      },
    },
  }),
);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type channelMemberOptionTypes =
  | 'text'
  | 'client'
  | 'company'
  | 'title'
  | 'no-option';

// ComboBoxOption can be used for many types of multi-selects
// so it contains properties that other select can use
export type ComboBoxOption = {
  label: string;
  type: channelMemberOptionTypes | 'email';
  id: string;
  getstreamId?: string;
  avatar?: string;
  fallbackColor?: string;
  companyName?: string;
  group?: string;
  style?: React.CSSProperties;
  deleteIconStyle?: React.CSSProperties;
  isPlaceholder?: boolean;
};

export interface ChannelMemberSelectComboBoxOption extends ComboBoxOption {
  companyId?: string;
  type: channelMemberOptionTypes;
}

interface AdditionalComboBoxProps {
  openOnFocus?: boolean;
  inputProps?: InputProps;
}

interface InputProps {
  value?: string;
  autoFocus?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface ComboBoxOwnProps {
  id: string;
  label: string;
  placeholder?: string;
  values: any[] | any;
  additionalComboBoxProps?: AdditionalComboBoxProps;
  withAvatars?: boolean;
  withChips?: boolean;
  showPlaceholder?: boolean;
  hideSelectedItems?: boolean;
  error?: boolean;
  helperText?: any;
  disabled?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  textFieldVariant?: TextFieldSizeVariant;
  disabledIDs?: string[];
  autoCompleteClasses?: Partial<Record<AutocompleteClassKey, string>>;
  avatarSize?: AvatarSizeType;
  open?: boolean; // Controls the open state of auto-complete
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  popupIcon?: React.ReactNode;
  noOptionsText?: string | React.ReactNode;
  closeIcon?: React.ReactNode;
  blurOnSelect?: boolean;
  fieldName?: string;
}

export type ComboBoxProps<
  Option extends ComboBoxOption = ComboBoxOption,
  Multiple extends boolean = true,
> = ComboBoxOwnProps &
  Pick<
    AutocompleteProps<Option, Multiple, Multiple, undefined>,
    'onChange' | 'options' | 'multiple' | 'groupBy'
  > &
  Pick<
    Partial<AutocompleteProps<Option, Multiple, Multiple, undefined>>,
    'filterOptions' | 'getOptionLabel'
  >;

function ComboBox<Option extends ComboBoxOption, Multiple extends boolean>({
  id,
  label,
  placeholder,
  showPlaceholder = true,
  options,
  values,
  onChange,
  onBlur,
  additionalComboBoxProps,
  filterOptions,
  withAvatars = false,
  avatarSize = 'mini',
  withChips = false,
  hideSelectedItems,
  multiple,
  error,
  helperText,
  disabled = false,
  textFieldVariant = 'skinny',
  disabledIDs = [],
  autoCompleteClasses = {},
  onKeyDown,
  open,
  popupIcon,
  groupBy,
  noOptionsText,
  closeIcon,
  blurOnSelect,
  fieldName,
}: ComboBoxProps<Option, Multiple>) {
  const { EnableSubscriptionRedesign } = useContext(FlagsContext);
  const classes = useStyles({ disabled, EnableSubscriptionRedesign });
  const { handleBlur } = useFormikContext();

  const getInputProps = (): InputProps => {
    if (additionalComboBoxProps) {
      if (additionalComboBoxProps.inputProps) {
        return additionalComboBoxProps.inputProps;
      }
    }
    return {};
  };

  const getOpenProps = () => (open !== undefined ? { open } : {});

  const CustomListboxComponent = React.forwardRef(
    (
      data: React.HTMLAttributes<HTMLDivElement>,
      ref: React.Ref<HTMLDivElement>,
    ) => {
      if (groupBy) {
        return <GroupListboxComponent ref={ref} {...data} />;
      } else {
        return <ListboxComponent ref={ref} {...data} />;
      }
    },
  );

  // openOnFocus indicates whether the dropdown list should open on focus or not
  const { openOnFocus = false } = additionalComboBoxProps || {};
  const getInputValueProp = () =>
    disabled && { inputValue: values ? values.label : '' };

  const selectedOptionsRenderer = (
    opts: ComboBoxOption[],
    getTagProps: any,
  ) => (
    <div className={classes.chipContainer}>
      {opts.map((option: ComboBoxOption, index: number) => (
        <BaseChip
          classes={{
            root: classes.root,
            deleteIcon: classes.closeIcon,
            label: classes.chipLabel,
          }}
          label={
            <div className={classes.avatarContainer}>
              {withAvatars && (
                <div
                  className={clsx({
                    [classes.companiesAvatarContainer]:
                      option.type === 'company',
                    [classes.clientsAvatarContainer]: option.type === 'client',
                  })}
                >
                  <UserAvatar
                    shape={option.type === 'company' ? 'rounded' : 'circle'}
                    name={option.label}
                    avatarUrl={option.avatar}
                    fallbackColor={option.fallbackColor}
                    avatarSize="16small"
                    initialLetters={
                      option?.type === 'company'
                        ? option.label.split(' ')[0]
                        : ''
                    }
                    avatarStyles={{
                      border: `1px solid ${NonHoverBorder}`,
                      boxSizing: 'border-box',
                    }}
                  />
                </div>
              )}
              <div
                className={clsx({
                  [classes.labelContainer]: !withAvatars,
                })}
              >
                <BaseTypography fontType="12Medium" textColor={BlackHeadings}>
                  {option.label}
                </BaseTypography>
              </div>
            </div>
          }
          {...getTagProps({ index })}
          style={option.style ? option.style : {}}
          deleteIconStyle={option.deleteIconStyle || {}}
        />
      ))}
    </div>
  );

  const isOptionDisabled = (option: ComboBoxOption) => {
    return (
      option.type === 'title' ||
      option.type === 'no-option' ||
      disabledIDs.some((disabledID) => option.id.indexOf(disabledID) > -1)
    );
  };

  const optionItemRenderer = (
    option: ComboBoxOption,
    { selected }: { selected: ComponentProps<typeof Checkbox>['checked'] },
  ) => {
    const noOption = option.type === 'no-option';

    if (noOption) {
      return (
        // no-option is a disabled option, and thus gets 0.6 opacity
        // applied to it by MUI. We use a darker font to offset this.
        <BaseTypography fontType="12Medium" textColor={black} opacity="1">
          No matches found for "{option.label}"
        </BaseTypography>
      );
    }

    if (withChips) {
      return (
        <BaseChip
          label={option.label}
          style={option.style ? option.style : {}}
        />
      );
    }
    return (
      <Box
        display="flex"
        justifyItems="space-between"
        alignItems="center"
        width={1}
      >
        {withAvatars && !noOption ? (
          <Box mr={1}>
            <UserAvatar
              type="image"
              primaryTextVariant="tableMain"
              name={option.label}
              avatarUrl={option.avatar}
              fallbackColor={option.fallbackColor}
              avatarSize={avatarSize}
              shape={option.type === 'company' ? 'rounded' : 'circle'}
              initialLetters={
                option?.type === 'company' ? option.label.split(' ')[0] : ''
              }
              avatarStyles={{
                border: `1px solid ${NonHoverBorder}`,
                boxSizing: 'border-box',
              }}
            />
          </Box>
        ) : (
          <>
            {!noOption && (
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
            )}
          </>
        )}

        <Box>
          <BaseTypography fontType="bodyMd" textColor={BlackHeadings}>
            {option.label}
          </BaseTypography>
          {option.companyName && (
            <BaseTypography>{option.companyName || ''}</BaseTypography>
          )}
        </Box>
      </Box>
    );
  };

  const inputRenderer: ComponentProps<typeof Autocomplete>['renderInput'] = (
    params,
  ) => {
    if (values?.label) {
      params.InputProps.startAdornment = (
        <>
          <InputAdornment position="start" className={classes.adornment}>
            <UserAvatar
              type="image"
              primaryTextVariant="tableMain"
              name={values.label}
              avatarUrl={values.avatar}
              fallbackColor={values.fallbackColor}
              avatarSize={avatarSize}
              shape={values.type === 'company' ? 'rounded' : 'circle'}
              initialLetters={
                values?.type === 'company' ? values.label.split(' ')[0] : ''
              }
              avatarStyles={{
                border: `1px solid ${NonHoverBorder}`,
                boxSizing: 'border-box',
              }}
            />
          </InputAdornment>

          {params.InputProps.startAdornment}
        </>
      );
    }

    return (
      <BaseTextField
        sizeVariant={textFieldVariant}
        {...params}
        label={label}
        name={fieldName}
        variant="outlined"
        placeholder={showPlaceholder ? placeholder || `Choose ${label}` : ''}
        fullWidth
        {...getInputProps()}
        error={error}
        className={classes.label}
        helperText={helperText}
        disabled={disabled}
        inputProps={{
          ...params.inputProps,
          isautocomplete: 'true',
        }}
        onBlur={handleBlur}
      />
    );
  };

  return (
    <Autocomplete<Option, Multiple, Multiple>
      blurOnSelect={blurOnSelect}
      limitTags={2}
      classes={{
        listbox: classes.list,
        paper: classes.listPaper,
        inputRoot: classes.inputRoots,
        groupLabel: classes.groupLabel,
        ...autoCompleteClasses,
      }}
      multiple={multiple}
      id={id}
      options={options}
      value={values}
      {...getInputValueProp()}
      {...getOpenProps()}
      onBlur={onBlur}
      filterOptions={filterOptions}
      onChange={onChange}
      onKeyDown={onKeyDown}
      popupIcon={
        popupIcon !== undefined ? (
          popupIcon
        ) : (
          <ChevronDownIcon className={classes.dropdownIcon} />
        )
      }
      ListboxComponent={CustomListboxComponent}
      renderGroup={RenderGroup}
      groupBy={groupBy}
      // to shrink row size for color chips list or small avatars
      ListboxProps={{
        chipsized: String(withChips || avatarSize === '18small'),
      }}
      renderTags={selectedOptionsRenderer}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option: ComboBoxOption) =>
        multiple
          ? values.find((item: any) => item.id === option.id)
          : values.id === option.id
      }
      renderOption={optionItemRenderer}
      renderInput={inputRenderer}
      disableClearable={multiple}
      openOnFocus={openOnFocus}
      closeIcon={closeIcon}
      filterSelectedOptions={hideSelectedItems}
      disabled={disabled}
      getOptionDisabled={(option) => isOptionDisabled(option)}
      noOptionsText={noOptionsText}
    />
  );
}

export default ComboBox;
