import { Events } from '../constants/webSocketConsts/events';
import { Dispatch } from 'redux';
import { ensureUnreachable } from '../utils/common_utils';
import { Invoice, invoicesApi } from '../services/api/invoicesApi';
import { RootState } from 'src/store';
import { arrayToMap } from 'src/utils/array';

const updateCache = (event: Events, items: Invoice[], draft?: Invoice[]) => {
  console.info('updateCache', { draft, event, items });

  if (!draft) {
    return;
  }

  items.forEach((invoiceObj) => {
    const upcomingInvoiceIndex = draft.findIndex(
      (item: Invoice) => item.id === invoiceObj.id,
    );

    switch (event) {
      case Events.Insert: {
        if (upcomingInvoiceIndex !== -1) {
          return;
        }

        draft.unshift(invoiceObj);
        break;
      }
      case Events.Modify:
        if (upcomingInvoiceIndex === -1) {
          draft.unshift(invoiceObj);
          return;
        }
        draft.splice(
          0,
          draft.length,
          ...draft.map((item: Invoice) =>
            item.id === invoiceObj.id ? invoiceObj : item,
          ),
        );
        break;
      case Events.Remove:
        if (upcomingInvoiceIndex === -1) {
          return;
        }
        draft.splice(upcomingInvoiceIndex, 1);
        break;
      case Events.Refresh:
      case Events.DomainVerified:
        return;
      default:
        ensureUnreachable(event);
    }
  });
};

export function handleInvoiceUpdate(
  event: Events,
  items: Invoice[],
  dispatch: Dispatch<any>,
  state: RootState,
) {
  dispatch(
    invoicesApi.util.updateQueryData(
      'getInvoices',
      undefined,
      (draft?: Invoice[]) => {
        updateCache(event, items, draft);
      },
    ),
  );

  for (const item of items) {
    const invoiceRecipientId = item.recipientId;
    const invoiceId = item.id;

    const { companies, clients } = state.clients;
    const companiesMap = arrayToMap(companies, 'id');
    // If found, this indicates that this is a company invoice
    const company = companiesMap.get(invoiceRecipientId);

    if (company) {
      const companyClientsIds = clients
        .filter((client) => client.fields.companyId === invoiceRecipientId)
        .map((client) => client.id);
      for (const clientId of companyClientsIds) {
        dispatch(
          invoicesApi.util.updateQueryData(
            'getInvoicesByClientId',
            clientId,
            (draft?: Invoice[]) => {
              updateCache(event, items, draft);
            },
          ),
        );
      }
    } else {
      dispatch(
        invoicesApi.util.updateQueryData(
          'getInvoicesByClientId',
          invoiceRecipientId,
          (draft?: Invoice[]) => {
            updateCache(event, items, draft);
          },
        ),
      );
    }

    // Updating cache for any invoices for invoice details view
    dispatch(
      invoicesApi.util.updateQueryData(
        'getInvoiceById',
        invoiceId,
        (draft?: Invoice) => {
          if (!draft) {
            return;
          }
          Object.assign(draft, item);
        },
      ),
    );
  }
}
