import { Company } from 'src/store/clients/types';
import { appAPI, ApiTags, ApiMethods } from '.';
import { Client } from 'src/constants';

type getCompaniesInput = {
  ignoreAccess?: boolean;
};

type CompanyInput = {
  name: string;
  clients: Array<{ name: string; email: string }>;
};

export type UnprocessedClientResponse = {
  Client: {
    name: string;
    email: string;
  };
  Error: string;
};

type CreateCompanyResponse = {
  Company: Company;
  Clients: Client[];
  UnprocessedClients: Array<UnprocessedClientResponse>;
};

export const companiesApi = appAPI.injectEndpoints({
  endpoints: (build) => ({
    createCompany: build.mutation<CreateCompanyResponse, CompanyInput>({
      query: (newCompany) => ({
        path: `/companies`,
        method: ApiMethods.post,
        options: {
          body: newCompany,
        },
      }),
      invalidatesTags: [ApiTags.clientCompanies],
    }),
    getCompanies: build.query<Company[], getCompaniesInput>({
      query: (getCompaniesInput) => ({
        path: `/companies`,
        method: ApiMethods.get,
        options: {
          queryStringParameters: getCompaniesInput,
        },
      }),
      providesTags: [ApiTags.clientCompanies],
    }),
  }),
});

export const { useGetCompaniesQuery, useCreateCompanyMutation } = companiesApi;
