import React, { useMemo } from 'react';
import { Button, Theme, Tooltip, makeStyles } from '@material-ui/core';
import {
  ColDef,
  GetQuickFilterTextParams,
  ICellRendererParams,
} from '@ag-grid-community/core';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import {
  useGetContractsQuery,
  ContractStatus,
  ContractTemplate,
  useGetContractTemplatesQuery,
} from 'src/services/api';
import StandardPage from 'src/components/UI/StandardPage';
import { LoadingWrapper } from 'src/components/Loading';
import AgGrid, { ChipCellRenderer } from 'src/components/AgGrid';
import {
  ArrowRightIcon,
  ForwardIcon,
  InformationCircleIcon,
} from 'src/components/Icons';
import { RootState } from 'src/store';
import { UserAvatar } from '../User';
import MemoBaseTypography from '../Text/BaseTypography';
import { LocaleDateFormatter } from '../Formatters';
import { getContractStatusInfo } from './ClientContractsTable';
import { SubmissionTableActionMenu } from './SubmissionAndRequestTable';
import { Contract } from 'src/entities/Contract';
import history from 'src/history';
import { CONTRACTS_PAGE, CONTRACT_DETAILS_PAGE } from 'src/constants';
import { ContractDetailsPageView } from './ContractDetailsPage';
import { black, white } from 'src/theme/colors';
import { typography13RegularStyle } from 'src/components/Text';
import { CreateButton } from 'src/components/UI';
import { useModuleIcon } from 'src/hooks/useModuleIcon';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    width: '100%',
    [theme.breakpoints.down('sm')]: { padding: 0, marginTop: 0 },
  },
  contractsRoot: {
    marginTop: theme.spacing(1.5),
    paddingLeft: theme.spacing(4.5),
    paddingRight: theme.spacing(4.5),
  },
  button: {
    justifyContent: 'flex-start',
    '&:hover': {
      background: 'transparent',
    },
  },
  // Styles for the contract template column
  contractTemplateCell: {
    // Hide the icon by default; it will be displayed on hover
    '& [data-attrib=hovered-icon]': {
      opacity: 0,
      transform: 'translateX(-100%)',
      transition: '.2s ease',
      width: 0,
    },
    '&:hover': {
      // Show the icon and move it back to its original position on hover
      '& [data-attrib=hovered-icon]': {
        opacity: 1,
        transform: 'translateX(0px)',
        width: 'auto',
      },
      // Hide the default icon during hover
      '& [data-attrib=default-icon]': {
        display: 'none',
      },
    },
  },
  infoIcon: {
    height: 16,
  },
  tooltip: {
    background: white,
    color: black,
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    ...typography13RegularStyle,
  },
}));

export const isContractTemplateDeleted = (
  templates: ContractTemplate[],
  templateId: string,
) => !templates.find((c) => c.id === templateId);

const ContractTemplateCellRenderer = ({ data }: { data: Contract }) => {
  const classes = useStyles();
  const { data: templates } = useGetContractTemplatesQuery();

  return (
    <Button
      className={classes.button}
      endIcon={
        isContractTemplateDeleted(templates || [], data.contractTemplateId) ? (
          <Tooltip
            classes={{
              tooltip: classes.tooltip,
            }}
            title={
              !data.contractTemplateId
                ? 'This is a one-off contract'
                : 'This contract template was deleted.'
            }
            placement="bottom-start"
          >
            <div>
              <InformationCircleIcon className={classes.infoIcon} />
            </div>
          </Tooltip>
        ) : (
          <>
            <ArrowRightIcon data-attrib="hovered-icon" />
            <ForwardIcon data-attrib="default-icon" />
          </>
        )
      }
    >
      <MemoBaseTypography>{data.name}</MemoBaseTypography>
    </Button>
  );
};

// this enum enumerates the kinds of lists that we show.
// All represents showing all clients (i.e for an internal user in the contracts app)
// while Client represents showing a filtered version of the same list by client id
// which we do in the client details page.
export enum SubmissionList {
  All = 'All',
  Client = 'Client',
}

type Props =
  | { type: SubmissionList.All }
  | { type: SubmissionList.Client; clientId: string };

export const SubmissionListTable = (props: Props) => {
  const classes = useStyles();
  const clients = useSelector((state: RootState) => state.clients.clients);
  const onClientDetail = props.type === SubmissionList.Client;
  const { data: contracts, isLoading: areContractsLoading } =
    useGetContractsQuery(onClientDetail ? { recipientId: props.clientId } : {});
  const { data: templates } = useGetContractTemplatesQuery();
  const searchKey = useSelector((state: RootState) => state.ui.searchValue);
  const Icon = useModuleIcon('contracts');

  let columns: ColDef[] = [
    // client detail page is already showing contracts assigned to a particular client
    // so as all the recipient column would be showing same client, so doesn't make sense to show the column
    ...(onClientDetail
      ? []
      : [
          {
            headerName: 'Recipient',
            field: 'recipient',
            minWidth: 350,
            sortable: true,
            comparator: (
              valueA: { givenName: string; familyName: string },
              valueB: { givenName: string; familyName: string },
            ) => {
              const firstFullName = `${valueA.givenName} ${valueA.familyName}`;
              const secondFullName = `${valueB.givenName} ${valueB.familyName}`;
              return firstFullName.localeCompare(secondFullName);
            },
            getQuickFilterText(filterParams: GetQuickFilterTextParams) {
              const { givenName, familyName, email } =
                filterParams.data.recipient;
              return `${givenName} ${familyName} ${email}`;
            },
            cellRendererFramework({ value }: ICellRendererParams) {
              return (
                <UserAvatar
                  avatarUrl={value.avatarImageUrl}
                  name={`${value.givenName} ${value.familyName}`}
                  description={value.email}
                  fallbackColor={value.fallbackColor}
                  primaryTextVariant="tableMain"
                  shape="circle"
                />
              );
            },
          },
        ]),
    {
      headerName: 'Contract template',
      field: 'name',
      cellRenderer: 'contractTemplateCellRenderer',
      minWidth: 400,
      sortable: true,
      cellClass: classes.contractTemplateCell,
      // redirect to contract details page when cell is clicked
      onCellClicked: (e) => {
        if (
          isContractTemplateDeleted(templates || [], e.data.contractTemplateId)
        )
          return;

        history.push(
          `${CONTRACT_DETAILS_PAGE.path}?templateId=${e.data.contractTemplateId}&view=${ContractDetailsPageView.SubmissionRequests}`,
        );
      },
    },
    {
      headerName: 'Sent on',
      field: 'shareDate',
      minWidth: 200,
      cellRendererFramework({ valueFormatted }: ICellRendererParams) {
        return <MemoBaseTypography>{valueFormatted}</MemoBaseTypography>;
      },
      getQuickFilterText(filterParams) {
        return new Date(filterParams.data.sentOn).toLocaleDateString();
      },
      valueFormatter: LocaleDateFormatter,
      sortable: true,
      sort: 'desc',
    },
    {
      headerName: 'Submitted on',
      field: 'submissionDate',
      minWidth: 200,
      cellRendererFramework({ valueFormatted }: ICellRendererParams) {
        return <MemoBaseTypography>{valueFormatted}</MemoBaseTypography>;
      },
      getQuickFilterText(filterParams) {
        return new Date(filterParams.data.sentOn).toLocaleDateString();
      },
      valueFormatter: LocaleDateFormatter,
    },
    {
      headerName: 'Status',
      field: 'status',
      minWidth: 150,
      sortable: true,
      cellRenderer: 'chipCellRenderer',
      cellRendererParams: {
        mapStatusToSeverity: (status: ContractStatus) =>
          getContractStatusInfo(status).severity,
      },
    },
    {
      colId: 'actions',
      width: 150,
      headerName: 'Actions',
      suppressSizeToFit: true,
      pinned: 'right',
      cellRenderer: 'templateActionRenderer',
    },
  ];

  const rowData = useMemo(() => {
    if (!contracts) return [];

    const contractsWithClientInfo = contracts.map((item) => ({
      ...item,
      recipient: clients.find((c) => c.id === item.recipientId)?.fields || {},
    }));

    return contractsWithClientInfo;
  }, [contracts, clients]);

  const shouldShowStandardPage = !rowData.length;

  const client = onClientDetail
    ? clients.find((c) => c.id === props.clientId)
    : undefined;

  const handleStandardPagePrimaryAction = () => {
    const targetUri = onClientDetail
      ? `${CONTRACTS_PAGE.path}`
      : `${CONTRACTS_PAGE.path}/edit`;
    history.push(targetUri);
  };

  const standardPageParams = {
    title: 'No contracts submitted or assigned',
    topIcon: <Icon />,
    desc:
      onClientDetail && client
        ? `Contracts assigned to or submitted by ${client.fields.givenName} ${client.fields.familyName} will appear here.`
        : "Any contract submissions and pending requests you've sent to clients will show here.",
    actionButton: (
      <CreateButton
        htmlId="btn-create-first-invoice"
        onClick={handleStandardPagePrimaryAction}
      >
        {onClientDetail ? 'Go to contracts' : 'New contract'}
      </CreateButton>
    ),
  };

  return (
    <LoadingWrapper
      fullHeight
      isLoading={areContractsLoading}
      hideContentWhileLoading
    >
      {shouldShowStandardPage ? (
        <StandardPage {...standardPageParams} />
      ) : (
        <div
          className={classNames(classes.root, {
            [classes.contractsRoot]: props.type !== SubmissionList.Client,
          })}
        >
          <AgGrid
            className="agGridRowHover"
            rowData={rowData}
            columnDefs={columns}
            searchKey={searchKey}
            onRowClicked={(value: Contract) => {
              history.push(
                `${CONTRACTS_PAGE.path}/submission?contractId=${value.id}`,
              );
            }}
            frameworkComponents={{
              templateActionRenderer: SubmissionTableActionMenu,
              chipCellRenderer: ChipCellRenderer,
              contractTemplateCellRenderer: ContractTemplateCellRenderer,
            }}
          />
        </div>
      )}
    </LoadingWrapper>
  );
};
