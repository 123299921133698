import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const PagesIcon = (props?: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M10.0001 18.9001C14.9154 18.9001 18.9001 14.9154 18.9001 10.0001C18.9001 5.08476 14.9154 1.1001 10.0001 1.1001C5.08476 1.1001 1.1001 5.08476 1.1001 10.0001C1.1001 14.9154 5.08476 18.9001 10.0001 18.9001Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.1001 10H18.9001"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.9997 1.1001C12.2258 3.53723 13.4909 6.70001 13.5597 10.0001C13.4909 13.3002 12.2258 16.463 9.9997 18.9001C7.77356 16.463 6.50845 13.3002 6.4397 10.0001C6.50845 6.70001 7.77356 3.53723 9.9997 1.1001V1.1001Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
