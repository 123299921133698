import { FieldVisibility, TablePropertyFields } from 'src/constants/dataTypes';

export const DefaultCRMColumnSettings: TablePropertyFields = {
  tablePropertyFields: [
    {
      id: 'userName',
      name: 'Name',
      disabled: false,
    },
    {
      id: 'company',
      name: 'Company',
      disabled: false,
    },
    {
      id: 'leadUser',
      name: 'Lead',
      disabled: false,
    },
    {
      id: 'assignedUsers',
      name: 'Assignees',
      disabled: false,
    },
    {
      id: 'status',
      name: 'Status',
      disabled: false,
    },
    {
      id: 'creationDate',
      name: 'Creation date',
      disabled: false,
    },
  ] as FieldVisibility[],
};
