import React from 'react';
import { type StripeElementsOptions } from '@stripe/stripe-js';

type StripeContextType = {
  options: StripeElementsOptions;
  updateOptions: (options: StripeElementsOptions) => void;
  resetOptions: () => void;
};

export const StripeContext = React.createContext<StripeContextType>({
  options: {},
  updateOptions: () => {},
  resetOptions: () => {},
});
