import { withStyles, createStyles } from '@material-ui/styles';
import { Badge } from '@material-ui/core';
import * as Colors from 'src/theme/colors';

export const AvatarBadge = withStyles(() =>
  createStyles({
    badge: {
      backgroundColor: Colors.BlackHeadings,
      color: Colors.white,
      minWidth: '13px',
      position: 'absolute',
      top: 20,
      bottom: 0,
      right: 2,
      width: '13px',
      height: '13px',
      borderRadius: '2px',
      border: '1px solid #fff',
      fontSize: 11,
      paddingTop: 0.5,
    },
  }),
)(Badge);
