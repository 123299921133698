import { Events } from 'src/constants/webSocketConsts/events';
import {
  deleteSubscriptionsAction,
  updateSubscriptionsAction,
} from 'src/store/payments/actions';
import { ensureUnreachable } from 'src/utils/common_utils';

const subscriptionHandler = (event: Events, items: Array<any>) => {
  if (items.length === 0) {
    return null;
  }
  switch (event) {
    case Events.Remove:
      return deleteSubscriptionsAction(items);
    case Events.Insert:
    case Events.Modify:
      return updateSubscriptionsAction(items);
    case Events.DomainVerified:
    case Events.Refresh:
      return null;
    default:
      return ensureUnreachable(event);
  }
};

export default subscriptionHandler;
