import { Events } from 'src/constants/webSocketConsts/events';
import {
  loadPortalSettingsAction,
  removePortalSettingsAction,
} from 'src/store/settings/actions';
import {
  ExtensionsSettings,
  ModuleSettingsItem,
  PortalSettings,
} from 'src/constants/dataTypes';
import { ensureUnreachable } from 'src/utils/common_utils';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { applicationSettingsApi } from 'src/services/api/applicationsApi';
import { automationsApi } from 'src/services/api/automationsApi';
import { ApiTags } from 'src/services/api';

const portalSettingsHandler = (event: Events, items: Array<PortalSettings>) => {
  if (items.length === 0) {
    return null;
  }
  switch (event) {
    case Events.Remove:
      removePortalSettingsAction(items);
      return handlePortalSettingsChanges(
        items.at(0)?.fields.moduleSettings || [],
        items.at(0)?.fields.extensionsSettings || {},
      );
    case Events.Insert:
    case Events.Modify:
      loadPortalSettingsAction(items);
      return handlePortalSettingsChanges(
        items.at(0)?.fields.moduleSettings || [],
        items.at(0)?.fields.extensionsSettings || {},
      );
    case Events.DomainVerified:
    case Events.Refresh:
      return null;
    default:
      return ensureUnreachable(event);
  }
};

export default portalSettingsHandler;

export const handlePortalSettingsChanges =
  (
    moduleSettings: ModuleSettingsItem[],
    extensionsSettings: ExtensionsSettings,
  ) =>
  async (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    dispatch(
      applicationSettingsApi.util.updateQueryData(
        'getInstalls',
        undefined,
        (draftState) => {
          draftState.moduleSettings = moduleSettings;
          draftState.extensionsSettings = extensionsSettings;
          return;
        },
      ),
    );
    // invalidate "GET: /automations/templates" to fetch the updated data
    dispatch(automationsApi.util.invalidateTags([ApiTags.automationTemplates]));
  };
