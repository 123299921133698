import React from 'react';
import {
  Box,
  CardMedia,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import {
  INITIAL_PAGE_PRIMARY_FONT,
  INITIAL_PAGE_SECONDARY_FONT,
} from 'src/constants/fontsConsts';
import * as Colors from 'src/theme/colors';

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      paddingTop: '10px',
      color: Colors.GraySmall,
    },
  }),
);
export interface OverlayParams {
  noRowsLabel: string;
  secondaryText?: string;
  newActionButton?: React.ReactNode;
  noDataImage?: string;
  actionButton?: React.ReactNode;
}

interface NoDataOverlayProps {
  overlayParams: () => OverlayParams;
}

const NoDataOverlay: React.FC<NoDataOverlayProps> = ({ overlayParams }) => {
  const { noRowsLabel, secondaryText, newActionButton, noDataImage } =
    overlayParams();
  const classes = useStyles();
  return (
    <Box margin="auto">
      <Typography variant={INITIAL_PAGE_PRIMARY_FONT} align="center">
        {noRowsLabel}
      </Typography>
      {secondaryText && (
        <Typography
          className={classes.label}
          variant={INITIAL_PAGE_SECONDARY_FONT}
          align="center"
        >
          {secondaryText}
        </Typography>
      )}
      {newActionButton && (
        <Box mt={1} display="flex" justifyContent="center">
          {newActionButton}
        </Box>
      )}
      {noDataImage && <CardMedia component="img" src={noDataImage} />}
    </Box>
  );
};

export default NoDataOverlay;
