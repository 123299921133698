import { INITIAL_BASE_ENTITY } from 'src/constants/entityConsts';

export enum LANDING_PAGE_STATUS {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}

export enum PublicSitePages {
  Home = 'LANDING_PAGE_HOME',
  About = 'LANDING_PAGE_ABOUT',
  Contact = 'LANDING_PAGE_CONTACT',
  Team = 'LANDING_PAGE_TEAM',
  Pricing = 'LANDING_PAGE_PRICE',
}

export const LANDING_DEFAULT_BACKGROUND = '/images/background_placeholder.png';
export const LANDING_DEFAULT_PROFILE = '/images/profile_placeholder.png';

export const INITIAL_LANDING_PAGE_DATA = {
  home: {
    ...INITIAL_BASE_ENTITY,
    entityStatus: LANDING_PAGE_STATUS.ACTIVE,
    fields: {
      pageId: 'home',
      pageEntityType: PublicSitePages.Home,
      pageName: 'Home',
      heroImageUrl: LANDING_DEFAULT_BACKGROUND,
      heroTitle: '',
      heroSubtitle: `Use this subtitle to highlight your firm's values, services, or something else that grabs attention`,
      aboutImageUrl: LANDING_DEFAULT_BACKGROUND,
      aboutDescription: `Use this section to describe the services your firm offers. Typically a few sentences that explain what you do and what you specialize in is perfect. You can expand on the details of the services you offer on the page dedicated to Services.`,
      teamImageUrl: LANDING_DEFAULT_BACKGROUND,
      teamDescription: `Use this section to provide clients some background information about your firm's history and give them a short summary about the people on the team.`,
    },
  },
  team: {
    ...INITIAL_BASE_ENTITY,
    entityStatus: LANDING_PAGE_STATUS.ACTIVE,
    fields: {
      pageId: 'team',
      pageEntityType: PublicSitePages.Team,
      pageName: 'Team',
      heroImageUrl: LANDING_DEFAULT_BACKGROUND,
      heroTitle: 'Meet The Team',
      heroSubtitle: 'Use this subtitle to highlight something about your team.',
      employees: [
        {
          id: 0,
          name: 'John Doe',
          role: 'President',
          imageUrl: LANDING_DEFAULT_PROFILE,
          description: `Use this section to expand on John Doe's credentials. You can add as many team member sections as you want.`,
        },
      ],
    },
  },
  aboutUs: {
    ...INITIAL_BASE_ENTITY,
    entityStatus: LANDING_PAGE_STATUS.ACTIVE,
    fields: {
      pageId: 'about_us',
      pageEntityType: PublicSitePages.About,
      pageName: 'Services',
      heroImageUrl: LANDING_DEFAULT_BACKGROUND,
      heroTitle: 'Our Services',
      heroSubtitle: 'Use this subtitle to highlight your services.',
      introduction:
        'Use this section to give an overall introduction to your services. Below, in each of the service cards, you can expand on each service you provide individually.',
      services: [
        {
          id: 0,
          title: 'Service 1',
          description:
            'Use this card to explain in-depth what Service 1 is about. You can add as many service cards as you want.',
        },
        {
          id: 1,
          title: 'Service 2',
          description:
            'Use this card to explain in-depth what Service 2 is about. You can add as many service sections as you want.',
        },
      ],
    },
  },
  contactUs: {
    ...INITIAL_BASE_ENTITY,
    entityStatus: LANDING_PAGE_STATUS.ACTIVE,
    fields: {
      pageId: 'contact_us',
      pageEntityType: PublicSitePages.Contact,
      pageName: 'Contact',
      heroImageUrl: LANDING_DEFAULT_BACKGROUND,
      heroTitle: 'Contact Us',
      heroSubtitle:
        'Use this subtitle to explain the preferred way you want clients to get in touch with you.',
      contactUsEmail: '',
      phoneNumber: '',
      daysOfBusiness: '',
      workHours: '',
      streetAddress: '',
      cityName: '',
      state: '',
      postalCode: '',
    },
  },
  pricing: {
    ...INITIAL_BASE_ENTITY,
    entityStatus: LANDING_PAGE_STATUS.DISABLED,
    fields: {
      pageId: 'pricing',
      pageEntityType: PublicSitePages.Pricing,
      pageName: 'Pricing',
      heroTitle: 'Pricing',
      heroSubtitle:
        'Use this subtitle to explain your subscriptions and products.',
      subscriptions: [
        {
          id: 0,
          title: 'Core',
          description: 'Ideal for small businesses',
          price: '99',
          priceDescription: '/month',
          features: [
            {
              id: 0,
              name: 'Feature 1',
            },
          ],
        },
      ],
    },
  },
};

export const LANDING_PAGES = [
  {
    name: 'home',
    type: PublicSitePages.Home,
  },
  {
    name: 'aboutUs',
    type: PublicSitePages.About,
  },
  {
    name: 'team',
    type: PublicSitePages.Team,
  },
  {
    name: 'contactUs',
    type: PublicSitePages.Contact,
  },
  {
    name: 'pricing',
    type: PublicSitePages.Pricing,
  },
];

export const LANDING_PAGES_IMAGES = {
  home: {
    headerImg: {
      width: 598,
      height: 411,
    },
    aboutImg: {
      width: 529.3,
      height: 357.02,
    },
    teamImg: {
      width: 529.3,
      height: 357.02,
    },
  },
  aboutUs: {
    heroImg: {
      width: 598,
      height: 411,
    },
  },
  contactUs: {
    heroImg: {
      width: 598,
      height: 411,
    },
  },
  team: {
    heroImg: {
      width: 598,
      height: 411,
    },
    employeeImg: {
      width: 175,
      height: 175,
    },
  },
};

export const InputLimits = {
  CharLimitHeroSubtitle: 150,
  CharLimitHomeSectionDescription: 500,
  CharLimitServiceIntroduction: 1000,
  CharLimitServiceSectionDescription: 500,
  CharLimitEmployeeDescription: 500,
};

export default INITIAL_LANDING_PAGE_DATA;
