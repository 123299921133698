import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const PagesDarkIcon = (props?: SvgIconProps) => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 7H4C2.897 7 2 7.897 2 9V19C2 20.103 2.897 21 4 21H16C17.103 21 18 20.103 18 19V9C18 7.897 17.103 7 16 7ZM4 19V11H16V9L16.002 19H4Z"
      fill="#212B36"
    />
    <path
      d="M22 5C22 3.897 21.103 3 20 3H19.001H16.999H7C5.897 3 5 3.897 5 5H7H16.587H18.001C19.102 5 19.997 5.895 20 6.994L20.002 15H20V17C21.103 17 22 16.103 22 15V8.007C22 8.004 22.001 8.002 22.001 8V6C22.001 5.997 22 5.994 22 5.99V5Z"
      fill="#212B36"
    />
  </SvgIcon>
);
