import React from 'react';
import { makeStyles } from '@material-ui/core';
import StandardPage from '.';
import { useModuleIcon } from 'src/hooks/useModuleIcon';
import { FILE_CHANNEL_EMPTY_STATE_LEARN_MORE } from 'src/constants/modulesEmptyStates';

interface NoFilesStandardPageProps {
  actionButton?: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'left', // make sure all text elements are left aligned
    padding: theme.spacing(0, 2),
  },
}));

const NoFilesStandardPage: React.FC<NoFilesStandardPageProps> = ({
  actionButton,
}) => {
  const classes = useStyles();
  const Icon = useModuleIcon('files');
  return (
    <div className={classes.root}>
      <StandardPage
        title="No files have been added yet"
        topIcon={<Icon />}
        desc="Files will be shown here when they are added by you or your client."
        learnMoreLinkUrl={FILE_CHANNEL_EMPTY_STATE_LEARN_MORE}
        titleForClient="No files have been added yet"
        descForClient="Files will be shown here when they are added by you or your service provider."
        actionButton={actionButton}
        actionButtonForClient={actionButton}
      />
    </div>
  );
};

export default NoFilesStandardPage;
