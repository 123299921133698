import React, { ComponentProps, useCallback } from 'react';
import {
  createStyles,
  Theme,
  makeStyles,
  ButtonBase,
  IconButton,
  Hidden,
  Avatar,
  Typography,
  Box,
  ClickAwayListener,
  AvatarTypeMap,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { getPublicImageURL } from 'src/utils/ImageUtils';
import BaseTypography from 'src/components/Text/BaseTypography';
import * as Colors from 'src/theme/colors';
import { AvatarBadge } from 'src/components/User/AvatarBadge';
import { ChevronDownIcon, CompanyIcon, UserIcon } from 'src/components/Icons';

import { BlackSmall } from 'src/theme/colors';
import { AvatarTextShadow } from 'src/theme/shadows';
import { AvatarContentRenderer } from 'src/components/User/AvatarContentRenderer';
import { GetCurrentUserFromState } from 'src/store/storeUtils';
import { useAppSelector } from 'src/hooks/useStore';

interface ColumnAvatarProps {
  avatarUrl?: string;
  name?: string;
  description?: string | number | React.ReactNode;
  fallbackColor?: string;
}
export type AvatarSizeType =
  | 'mini'
  | '16small'
  | '18small'
  | 'xxxsmall'
  | '25small'
  | 'xsmall'
  | '28small'
  | '40Small'
  | 'small'
  | 'medium'
  | 'medium-large'
  | 'large'
  | 'regularLarge'
  | 'regular';

const AVATAR_SPACING_MAP: Partial<Record<AvatarSizeType, string>> = {
  xxxsmall: '8px',
  '28small': '8px',
  '16small': '4px',
};

type PrimaryTextVariant =
  | ComponentProps<typeof Typography>['variant']
  | 'tableMain';
type SecondaryTextVariant =
  | ComponentProps<typeof Typography>['variant']
  | 'tableSubtitle';

interface UserAvatarProps extends ColumnAvatarProps {
  type?: 'row' | 'rowButton' | 'column' | 'image' | 'uploader';
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  showName?: boolean;
  showCaret?: boolean;
  nameSize?: 'h6' | 'body1' | 'h3' | 'body1';
  showTime?: boolean;
  showEmail?: boolean;
  showAvatar?: boolean;
  avatarSize?: AvatarSizeType;
  showAddress?: boolean;
  typeInitial?: string;
  append?: string;
  fallbackTypographyVariant?: 'body1' | 'h3';
  primaryTextVariant?: PrimaryTextVariant;
  secondaryTextVariant?: SecondaryTextVariant;
  shape?: AvatarTypeMap['props']['variant'];
  initialLetters?: string;
  avatarStyles?: React.CSSProperties;
  isLoading?: boolean;
  isActive?: boolean;
}

const TypeAvatar = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 15,
      height: 15,
      border: `2px solid ${theme.palette.background.paper}`,
      backgroundColor: Colors.BlackHeadings,
    },
  }),
)(Avatar);

interface StyleProps {
  avatarSize: AvatarSizeType;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    authButton: {
      marginLeft: theme.spacing(1),
      color: theme.palette.primary.contrastText,
      padding: '4px',
    },
    avatarContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    avatar16: {
      height: 16,
      width: 16,
    },
    avatar18: {
      height: 18,
      width: 18,
    },
    avatarMini: {
      height: 20,
      width: 20,
    },
    avatarXXXSmall: {
      height: 22.5,
      width: 22.5,
    },
    avatarXXSmall: {
      height: 25,
      width: 25,
    },
    avatar28Small: {
      height: 28,
      width: 28,
    },
    avatarXSmall: {
      height: 32,
      width: 32,
    },
    avatarSmall: {
      height: 40,
      width: 40,
    },
    avatar40Small: {
      height: 40,
      width: 40,
    },
    avatarMedium: {
      height: 64,
      width: 64,
      fontSize: 28,
    },
    avatarMediumLarge: {
      height: 50,
      width: 50,
      fontSize: 28,
    },
    avatarLarge: {
      height: 120,
      width: 120,
      fontSize: 45,
    },
    avatarRegular: {
      height: 30,
      width: 30,
    },
    avatarRegularLarge: {
      height: 35,
      width: 35,
    },
    row: {
      padding: theme.spacing(0.5, 0),
      borderRadius: 0,
    },
    rowWithCaret: {
      padding: theme.spacing(0.5, 1),
      borderRadius: 0,
      '&:hover': {
        background: Colors.HoverNonBorderBackground,
        borderRadius: theme.shape.borderRadius,
      },
    },
    accountName: {
      marginLeft: theme.spacing(1),
    },
    address: {
      marginTop: 10,
    },
    columnAvatar: {
      width: '100%',
    },
    details: {
      marginLeft: (props: StyleProps) =>
        AVATAR_SPACING_MAP[props.avatarSize] ?? theme.spacing(2),
    },
    fallbackTypographyVariant: {
      color: '#F4F6F8',
      textShadow: AvatarTextShadow,
    },
    caret: {
      marginLeft: theme.spacing(1),
      height: 10,
      width: 10,
      color: BlackSmall,
    },
    iconDown: {
      transition: 'all 200ms linear',
      transform: 'rotate(0deg)',
    },
    iconUp: {
      transition: 'all 200ms linear',
      transform: 'rotate(180deg)',
    },
    authActionActive: {
      backgroundColor: Colors.HoverNonBorderBackground,
    },
  }),
);

const UnMemoizedUserAvatar: React.FC<UserAvatarProps> = ({
  typeInitial,
  type = 'row',
  onClick,
  showName = false,
  showTime = false,
  showEmail = false,
  showAvatar = true,
  nameSize = 'body1',
  avatarSize = 'regular',
  shape = 'rounded',
  showAddress = false,
  avatarUrl,
  name = '',
  initialLetters = '',
  description = '',
  fallbackColor = '',
  append = '',
  showCaret = false,
  primaryTextVariant,
  secondaryTextVariant,
  avatarStyles,
  isLoading = false,
  isActive = false,
}) => {
  const [isFocused, setFocusedStatus] = React.useState(false);
  const classes = useStyles({ avatarSize });
  const user = useAppSelector((state) => GetCurrentUserFromState(state));

  const {
    avatarImageUrl = '',
    givenName,
    familyName,
    email,
    address,
    fallbackColor: userFallbackColor,
  } = user?.fields || {};

  const getAvatarSize = () => {
    if (avatarSize === 'medium') {
      return classes.avatarMedium;
    }
    if (avatarSize === 'medium-large') {
      return classes.avatarMediumLarge;
    }
    if (avatarSize === 'large') {
      return classes.avatarLarge;
    }
    if (avatarSize === 'small') {
      return classes.avatarSmall;
    }

    if (avatarSize === 'xsmall') {
      return classes.avatarXSmall;
    }

    if (avatarSize === '25small') {
      return classes.avatarXXSmall;
    }

    if (avatarSize === 'xxxsmall') {
      return classes.avatarXXXSmall;
    }

    if (avatarSize === 'mini') {
      return classes.avatarMini;
    }
    if (avatarSize === '16small') {
      return classes.avatar16;
    }

    if (avatarSize === '18small') {
      return classes.avatar18;
    }

    if (avatarSize === 'regularLarge') {
      return classes.avatarRegularLarge;
    }

    if (avatarSize === '28small') {
      return classes.avatar28Small;
    }

    if (avatarSize === '40Small') {
      return classes.avatar40Small;
    }

    return classes.avatarRegular;
  };

  const getAddress = () => {
    const addressParse = (address && JSON.parse(address)) || {};

    if (addressParse && addressParse.country && addressParse.state) {
      return `${addressParse.state}, ${addressParse.country}`;
    }

    return '';
  };

  const formatTimeZone = () => {
    const timeZoneSplit = new Date().toString().split(' ');
    return timeZoneSplit.at(5);
  };

  const wrapSkeleton = (skeletonType: string, returnValue: any) => {
    const skeleton =
      skeletonType === 'avatar' ? (
        <Skeleton variant="circle" className={getAvatarSize()} />
      ) : (
        <Skeleton variant="text" />
      );
    if (isLoading) {
      return skeleton;
    }
    return returnValue;
  };

  const timeZineinfo = formatTimeZone();

  const timeZone = showTime ? wrapSkeleton('text', timeZineinfo) : null;

  const userEmail = showEmail ? wrapSkeleton('text', email) : null;

  const userAddress = wrapSkeleton('text', getAddress());

  const getAvatarSrc = () => {
    if (avatarUrl !== undefined && avatarUrl !== null) {
      const size = avatarSize === 'large' ? 150 : 64;
      return getPublicImageURL(avatarUrl, {
        resize: {
          width: size,
          height: size,
        },
      });
    }

    if (avatarImageUrl && type !== 'row' && type !== 'image') {
      return avatarImageUrl;
    }

    return '';
  };

  const getCustomAvatarShape = useCallback(() => {
    if (shape) {
      return { variant: shape };
    }

    return null;
  }, [shape]);

  const getTypeInitialIcon = (): JSX.Element | null => {
    switch (typeInitial) {
      case 'I':
        return <UserIcon style={{ fontSize: '9px' }} />;
      case 'C':
        return <CompanyIcon style={{ fontSize: '9px' }} />;
      default:
        return null;
    }
  };

  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    if (onClick) {
      onClick(event);
    }
    setFocusedStatus(true);
  };

  const getStyles = React.useCallback((): React.CSSProperties => {
    return {
      backgroundColor: getAvatarSrc()
        ? '#fff'
        : fallbackColor || userFallbackColor, // first user color passed in prop, if not present fallback to current user color

      opacity: showAvatar ? 1 : 0,
      ...avatarStyles,
    };
  }, [
    avatarStyles,
    fallbackColor,
    getAvatarSrc,
    showAvatar,
    userFallbackColor,
  ]);

  const AvatarElement = wrapSkeleton(
    'avatar',
    <AvatarBadge
      overlap="circular"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      badgeContent={
        typeInitial ? (
          <TypeAvatar variant="rounded">{getTypeInitialIcon()}</TypeAvatar>
        ) : null
      }
    >
      <Avatar
        alt="User"
        src={getAvatarSrc()}
        className={`${getAvatarSize()}`}
        {...getCustomAvatarShape()}
        style={getStyles()}
      >
        <AvatarContentRenderer
          avatarSize={avatarSize}
          initialLetters={initialLetters}
          familyName={familyName || ''}
          givenName={givenName || ''}
          type={type}
          name={name}
        />
      </Avatar>
    </AvatarBadge>,
  );

  const userName = showName
    ? wrapSkeleton('text', `${givenName || ''} ${familyName || ''}`)
    : null;

  const CaretElement =
    showCaret && !isLoading ? (
      <ChevronDownIcon
        className={clsx(classes.caret, {
          [classes.iconUp]: isActive,
          [classes.iconDown]: !isActive,
        })}
      />
    ) : null;

  const inlineButtonAvatar = (
    <Box
      id="avatar-btn"
      display="flex"
      alignItems="center"
      component={showName ? ButtonBase : IconButton}
      onClick={handleAvatarClick}
      className={showCaret ? classes.rowWithCaret : classes.row}
    >
      {AvatarElement}
      {userName}
      {CaretElement}
    </Box>
  );

  const inlineAvatar = (showDetail: boolean) => (
    <div className={classes.avatarContainer}>
      {AvatarElement}
      {showDetail && (
        <Box className={classes.details}>
          <Box textAlign="left">
            {/** Todo(@Monfernape): Bad variants */}
            {primaryTextVariant === 'tableMain' ? (
              <BaseTypography fontType="12Medium">
                {name + append}
              </BaseTypography>
            ) : (
              <Typography hidden={!showName} variant={primaryTextVariant}>
                {name + append}
              </Typography>
            )}
            {secondaryTextVariant === 'tableSubtitle' ? (
              <BaseTypography fontType="12Regular">
                {description}
              </BaseTypography>
            ) : (
              <Typography variant={secondaryTextVariant}>
                {description}
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </div>
  );

  const columnAvatar = (
    <Box className={classes.columnAvatar}>
      <Box display="flex" justifyContent="center">
        {AvatarElement}
      </Box>
      <Box mt={2} textAlign="center">
        <Hidden smDown>
          <Typography variant={nameSize} color="inherit">
            {userName}
          </Typography>
        </Hidden>
        <Typography variant="h6" color="textSecondary">
          {userEmail}
        </Typography>
        {showAddress && (
          <Typography
            className={classes.address}
            color="textPrimary"
            variant="h6"
          >
            {userAddress}
          </Typography>
        )}

        <Typography color="textSecondary" variant="h6">
          {timeZone}
        </Typography>
      </Box>
    </Box>
  );

  const renderAvatar = () => {
    if (type === 'uploader') {
      return AvatarElement;
    }
    if (type === 'column') {
      return columnAvatar;
    }
    if (type === 'rowButton') {
      return inlineButtonAvatar;
    }
    if (type === 'image') {
      return inlineAvatar(false);
    }

    return inlineAvatar(true);
  };

  const removeActiveState = () => {
    setFocusedStatus(false);
  };

  return (
    <ClickAwayListener onClickAway={removeActiveState} disableReactTree>
      <div
        data-testid="user-avatar"
        className={isFocused ? classes.authActionActive : ''}
      >
        {renderAvatar()}
      </div>
    </ClickAwayListener>
  );
};

const UserAvatar = React.memo(UnMemoizedUserAvatar);

export default UserAvatar;
