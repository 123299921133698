import { BaseEntity } from 'src/constants';
import { LANDING_PAGE_STATUS } from 'src/constants/landingConsts';

export interface LandingPageEntity extends BaseEntity {
  fields:
    | HomePageEntity
    | TeamPageEntity
    | AboutUsPageEntity
    | ContactUsPageEntity
    | PricingPageEntity;
  entityStatus: LANDING_PAGE_STATUS;
}

export interface MarketingSiteDataType {
  showBadge: boolean;
  pages: LandingPageEntity[];
}

export interface PageDataEntity {
  pageId: string;
  pageEntityType: string;
  pageName: string;
  isActive: boolean;
  lastModified?: string;
}

export interface LandingHomeChangeForm extends LandingHeaderChangeForm {
  aboutImageUrl: string;
  aboutDescription: string;
  teamImageUrl: string;
  teamDescription: string;
  isActive: boolean;
}

export interface HomePageEntity
  extends Omit<PageDataEntity, 'isActive'>,
    Omit<LandingHomeChangeForm, 'isActive'> {}

export interface TeamEmployee {
  id: number;
  name: string;
  role: string;
  imageUrl: string;
  description: string;
}

export interface AboutPageServices {
  id: number;
  title: string;
  description: string;
}

export interface LandingTeamChangeForm extends LandingHeaderChangeForm {
  employees: TeamEmployee[];
  isActive: boolean;
}

export interface TeamPageEntity
  extends Omit<PageDataEntity, 'isActive'>,
    Omit<LandingTeamChangeForm, 'isActive'> {}

export interface LandingAboutUsChangeForm extends LandingHeaderChangeForm {
  introduction: string;
  services: AboutPageServices[];
  isActive: boolean;
}

export interface AboutUsPageEntity
  extends Omit<PageDataEntity, 'isActive'>,
    Omit<LandingAboutUsChangeForm, 'isActive'> {}

export type LandingChangeForm =
  | LandingHomeChangeForm
  | LandingTeamChangeForm
  | LandingContactUsChangeForm
  | LandingAboutUsChangeForm
  | LandingPricingChangeForm;

export interface LandingHeaderChangeForm {
  heroImageUrl: string;
  heroTitle: string;
  heroSubtitle: string;
}

export interface LandingContactUsChangeForm extends LandingHeaderChangeForm {
  contactUsEmail: string;
  phoneNumber: string;
  daysOfBusiness: string;
  workHours: string;
  streetAddress: string;
  cityName: string;
  state: string;
  postalCode: string;
  isActive: boolean;
}

export interface ContactUsPageEntity
  extends Omit<PageDataEntity, 'isActive'>,
    Omit<LandingContactUsChangeForm, 'isActive'> {}

export interface PricingSubscriptionFeature {
  id: number;
  name: string;
}

export interface PricingSubscriptions {
  id: number;
  title: string;
  description: string;
  price: string;
  priceDescription: string;
  features: PricingSubscriptionFeature[];
}

export interface LandingPricingChangeForm
  extends Omit<LandingHeaderChangeForm, 'heroImageUrl'> {
  subscriptions: PricingSubscriptions[];
  isActive: boolean;
}

export interface PricingPageEntity
  extends Omit<PageDataEntity, 'isActive'>,
    Omit<LandingPricingChangeForm, 'isActive'> {}

export interface LandingPagesModel {
  home: LandingPageEntity;
  team: LandingPageEntity;
  aboutUs: LandingPageEntity;
  contactUs: LandingPageEntity;
  pricing: LandingPageEntity;
}

export interface QueryOnLanding {
  page: string;
}

export interface LandingState {
  data: LandingPagesModel;
  isLoaded: boolean;
  isLoading: boolean;
  isUpdated: boolean;
  isUpdating: boolean;
}

export const LOAD_LANDINGS_REQUEST = 'LOAD_LANDINGS_REQUEST';
export const LOAD_LANDINGS_DONE = 'LOAD_LANDINGS_DONE';
export const LOAD_LANDINGS_FAIL = 'LOAD_LANDINGS_FAIL';
export const UPDATE_LANDING_SUCCESS = 'UPDATE_LANDING_SUCCESS';
export const UPDATE_LANDING_REQUEST = 'UPDATE_LANDING_REQUEST';
export const UPDATE_LANDING_FAILURE = 'UPDATE_LANDING_FAILURE';

interface StartLoadLandingsAction {
  type: typeof LOAD_LANDINGS_REQUEST;
}

interface LoadLandingsDoneAction {
  type: typeof LOAD_LANDINGS_DONE;
  payload: LandingPagesModel;
}

interface LoadLandingsFailAction {
  type: typeof LOAD_LANDINGS_FAIL;
  error: string;
}

export interface UpdateLandingSuccess {
  type: typeof UPDATE_LANDING_SUCCESS;
  data: LandingPageEntity;
}

export interface UpdateLandingFail {
  type: typeof UPDATE_LANDING_FAILURE;
  error: string;
}

export interface UpdateLandingStart {
  type: typeof UPDATE_LANDING_REQUEST;
}

export type LandingActionTypes =
  | StartLoadLandingsAction
  | LoadLandingsDoneAction
  | LoadLandingsFailAction
  | UpdateLandingSuccess
  | UpdateLandingFail
  | UpdateLandingStart;
